import service from "utils/service";
const xlyzApi = {
  pushMentalHealing: params => {
    return service.post("/neighborPeopleShare/pushMentalHealing", params);
  },
  getChatList: (params) => {
    return service.post('/neighborChat/chatList', params);
  },
  applyChatTeam: (params) => {
    return service.post('/neighborChat/add', params);
  },
  createChat: (params) => {
    return service.post('/neighborNeedChat/add', params);
  }
};
export default xlyzApi;
