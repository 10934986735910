import service from 'utils/service';

const llcwApi = {
  // 查询我的预约单
  getMyOrders: (params) => {
    return service.get('/shareParkingOrder/selectShareParkingOrder', {params});
  },
  // 添加共享车位前 查看我的车位列表
  getMyParking: (params) => {
    return service.get('/shareParking/getMyParking', {params});
  },
  // 添加共享车位
  addPark: (params) => {
    return service.post('/shareParking/add', params)
  },
  // 删除图片
  deletePicture: (params) => {
    return  service.get('/file/deletePic', {params});
  },

  // 上传车位图片
  uploadParkingImage: (params) => {
    return service.post('/file/uploadParkingImage', params);
  },
  // 查看我的共享车位
  selectMyShareParking: (params) => {
    return service.get('/shareParking/selectMyShareParking', {params});
  },
  // 选择车位后 获取车位的状态
  getParkingStatus: (params) => {
    return service.get('/shareParking/getParkingStatus', {params});
  },
  // 查看车位的预约信息
  getShareParkingOrderByPage: (params) => {
    return service.get('/shareParkingOrder/getShareParkingOrderByPage', {params});
  },
  // 获取共享车位信息
  getParkById: (params) => {
    return service.get('/shareParking/getById', {params});
  },
  // 更新共享车位前检查校验
  updateValidate: (params) => {
    return service.get('/shareParking/updateValidate', {params});
  },
  // 停止共享车位
  stopShare: (params) => {
    return service.get('/shareParking/stopShare', {params});
  }, 
  // 更新共享车位
  updatePark: (params) => {
    return service.post('/shareParking/update', params);
  },
  // 开启车位共享
  startShare: (params) => {
    return service.get('/shareParking/startShare', {params});
  },
  // 初始化最初的共享车位列表
  selectParking: (params) => {
    return service.post('/shareParking/selectParking', params);
  },
  // 查找空闲车位时筛选社区
  getCommunityList: (params) => {
    return service.get('/shareParking/getCommunityList', {params});
  },
  // 查看空闲车位
  selectFreeParking: (params) => {
    return service.post('/shareParking/selectFreeParking', params);
  },
  // 获取失效的预约 类似消息提示，用户进入后初始化调用
  getCancelParking: (params) => {
    return service.get('/shareParking/getCancelParking', {params})
  }, 
  // 查看该日预约该车位的预约信息
  selectOtherOrder: (params) => {
    return service.get('/shareParkingOrder/selectOtherOrder', {params})
  },
  // 新增预约车位单
  addOrder: (params) => {
    return service.post('/shareParkingOrder/add', params);
  }, 
  // 获取预约单信息
  getOrderById: (params) => {
    return service.get('/shareParkingOrder/getById', {params})
  },
  // 更新共享车位预约单
  updateOrder: (params) => {
    return service.post('/shareParkingOrder/update', params);
  },
  // 取消预约单
  deleteOrder: (params) => {
    return service.get('/shareParkingOrder/delete', {params})
  },
  // 取消预约单
  getCarCountList: (params) => {
    return service.get('/screen/statisticsNum', {params})
  },
}

export default llcwApi;