import service from 'utils/service'
const sdbxtApi = {

  //志愿者报名
  addVolunteer: (params) => {
    return service.post('/classVolunteer/addVolunteer', params)
  },

  //查询今日志愿者报名数
  getVolunteerList: (params) => {
    return service.get('/thirdVolunteer/getVolunteerShow', params)
  },

  //托管预约添加
  addPreview: (params) => {
    return service.post('/classDespoint/add', params)
  },

  //我的托管，进行中和结束查询
  queryPreviewList: (params) => {
    return service.post('/classDespoint/listByStatus', params)
  },

  //取消托管
  cancelHostPreview: (id) => {
    return service.get(`/classDespoint/cancel/${id}`)
  },

  //预约动态接口
  previewDynamic: (params) => {
    return service.post("/classDespoint/listDespoint", params)
  },

  // 查询当前用户是否报名
  checkStatus: () => {
    return service.get('classVolunteer/getDetail');
  },
  
  // 取消报名
  cancelVolunteer: () => {
    return service.get('classVolunteer/cancelDetail');
  },
  // 取消报名
  getDateByPage: () => {
    return service.get('thirdActivityDate/getDateByPage');
  },
  // 取消报名
  thirdVolunteerAdd: (params) => {
    return service.post('thirdVolunteer/add', params);
  },

  //---- 牛皮的后端大佬们新增的接口 ----//

  // 生成志愿活动日期
  getVolunteerTime: (params) => {
    return service.get('/thirdVolunteer/getVolunteerTime', {params});
  },
  // 获取当前用户是否报名
  isSign: (params) => {
    return service.get('/thirdVolunteer/isSign', {params});
  },
  // 志愿活动报名
  applyVolunteer: (params) => {
    return service.post('/thirdVolunteer/add', params);
  },
  // 分页查询自己的志愿申请记录
  getVolunteerRecord: params => {
    return service.get('/thirdVolunteer/getByPage', {params});
  },
  // 查看报名详情
  getApplyInfo: params => {
    return service.get('/thirdVolunteer/getById', {params});
  },
  // 取消报名
  disApply: params => {
    return service.get('/thirdVolunteer/cancel', {params});
  },

  // 托管预约
  // 查询托管对象和学期托管
  getTerm: params => {
    return service.get('/classDespoint/getTerm', {params});
  },
  // 
}
export default sdbxtApi
