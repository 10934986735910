import axios from 'axios';

const state = {
  studyItemSelect: '学堂详情',
}

const actions = {
}

const mutations = {
  setStudyItemSelect(state, data) {
    state.studyItemSelect = data;
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
}