import axios from 'axios';
import homeApi from 'api/home';
import { Toast, Notify } from 'vant';

const state = {
  activeKey: 0,
  selectedCatalog: '',
  sideBar: null,
  menuList: [],
  name: '',
  subTitle: '',
}

const actions = {
  // 获取菜单
  getCatalogData({commit, state}, params) {
    homeApi.getCatalogData({
      name: state.name,
      type: state.selectedCatalog,
      ...params
    }).then(res => {
      console.log('res', res);
      if(res.flag) {
        commit('setMenuList', res.data);
        const arr = (res && res.data ? res.data : []).filter(v => v.env).map(v => v.env);
        const list = Array.from(new Set(arr));
        console.log(list);
        commit('setSubTitle', list.length > 0 ? list : []);
      }
    })
  },
}

const mutations = {
  setSideBar(state, sideBar) {
    state.sideBar = sideBar;
  },
  setMenuList(state, data) {
    state.menuList = data;
  },
  setName(state, name) {
    state.name = name;
  },
  setSubTitle(state, subTitle) {
    state.subTitle = subTitle;
  },
  setSelectedCatalog(state, data) {
    state.selectedCatalog = data;
  },
  setActiveKey(state, data) {
    state.activeKey = data;
  }
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
}