import service from 'utils/service';
const buildingApi = {
  // 获取场地列表
  getSharedSites: params => {
    return service.post('/party-share-place/getSharePlaceList', params);
  },
  // 获取场地详情
  getSharedSiteDetail: params => {
    return service.get('/party-share-place/getSharePlace', { params });
  },
  // 获取契约共建列表类型
  getOrganizationJoint: params => {
    return service.get('/common/getOrganizationJoint', { params });
  },
  // 获取契约共建列表
  getJointList: params => {
    return service.post('/party-organization-joint/getJointList', params);
  },
  // 获取东盛共建岗列表
  getCommunityList: params => {
    return service.post('/party-community-joint/getJointList', params);
  },
  // 获取东盛共建岗类型
  getCommunityType: params => {
    return service.get('/common/getCommunityJoint', { params });
  },
  // 获取东盛共建-社区小匠人列表
  getWiseManList: params => {
    return service.post('/partyNeighWeek/getService', params);
  },
  // 社区小匠人-发布需求
  applyWiseManNeed: params => {
    return service.post('/partyNeighNeed/insertOne', params);
  },
  // 社区小匠人-服务类型
  getWiseManServiceType: params => {
    return service.get('/common/getServiceTypes', params);
  },
}
export default buildingApi;