
import service from 'utils/service';
export default {
  getCatalogSide: (params) => {
    return service.post('/screenModule/selectModuleType', params);
  },
  getCatalogData: (params) => {
    return service.post('/screenModule/getModuleInfo', params);
  },
  // 统计
  getTodayList: params => {
    return service.get('/common/stats', { params });
  },
  getStatisticList: params => {
    return service.get('/common/stats?days=total', {params});
  },
  // 常青课堂
  getEverGreenList: params => {
    return service.get('/educateCheapLearn/list', {params});
  },
  // 共享文件
  uploadSharedFiles: params => {
    return service.post('/sharePrintFile/uploadMultiFile', params)
  },
  getSharedFiles: params => {
    return service.get('/sharePrintFile/fromApp', {params});
  },
  deleteSharedFile: params => {
    return service.get('/sharePrintFile/delete', {params});
  },
  deleteAllSharedFile: params => {
    return service.get('/sharePrintFile/deleteAll', { params });
  },
  listInviter: params => {
    return service.get('/invitation/listInviter', { params });
  },
  getUserInfo: params => {
    return service.get('/invitation/getUserInfo', { params });
  },
  // 修改用户信息-获取用户信息
  getUserDetailInfo: params => {
    return service.get('/invitation/getUserDetailInfo', { params });
  },
  // 修改用户信息
  updateUserDetailInfo: params => {
    return service.post('/baseHousePerson/updateUserDetailInfo', params);
  },
  getAllDictByType: params => {
    return service.get('/common/getAllDictByType', {params});
  },
  getArticleList: params => {
    return service.post('/party-article/getArticleList', params);
  },
  // 验证手机号是否有归属
  checkPhone: params => {
    return service.get('/baseHousePerson/idcardAndPhoneVerify', { params });
  },
  // 党建首页数据
  getNewPartyBuildingNumbers: params => {
    return service.get('/dsscreen/h5/getIndexInfo', { params });
  },
  // 流程图点击信息 机关在职党员
  getParty: (params) => {
    return service.get("/dsscreen/h5/getParty", {params});
  },
  // 党支部信息 功能性党组织
  getPartyOrganization: (params) => {
    return service.get("/dsscreen/dangJianDaPingDssq/getPartyOrganization", {params});
  },
  // 党支部成员信息
  getPartyOrganizationMember: (params) => {
    return service.post("/dsscreen/dangJianDaPingDssq/getPartyOrganizationMember", params);
  },
  // 党总支信息
  getPartyGeneralBbranchMember: (params) => {
    return service.get("/dsscreen/dangJianDaPingDssq/getPartyGeneralBbranchMember", {params});
  },
  // 红色楼道长小组
  getPartyGroup: (params) => {
    return service.get("/dsscreen/dangJianDaPingDssq/getPartyGroup", {params});
  },
  // 党支部成员信息
  getPartyGroupMember: (params) => {
    return service.post("/dsscreen/dangJianDaPingDssq/getPartyGroupMember", params);
  },
  // 签订社区公约
  classifySign: params => {
    return service.get('/neighborGeneralRuleRecord/signRule', { params });
  },
  // 查询签约状态
  getClassifySignStatus: params => {
    return service.get('/neighborGeneralRuleRecord/getSignRuleStatus', { params });
  },
}