import service from "utils/service";
const wyslApi = {
  pushGame: params => {
    return service.post("/neighborGame/pushGame", params);
  },
  cancelGame: params => {
    return service.post("/neighborGame/cancelGame", params);
  },
  getGamePage: params => {
    return service.post("/neighborGame/getGamePage", params);
  },
  getGameEnroll: params => {
    return service.get("/neighborGameEnroll/getGameEnroll", { params });
  },
  getActivityInfo: params => {
    return service.get("/neighborGame/getGameDetail", { params });
  },
  // 活动反馈
  addActivityFk: params => {
    return service.post('/neighborGameEvaluate/addEvaluate', params);
  },
  addEnroll: params => {
    return service.post("/neighborGameEnroll/addEnroll", params);
  },
  repeatEnroll: params => {
    return service.post("/neighborGameEnroll/repeatEnroll", params);
  },
  cancelEnroll: params => {
    return service.post("/neighborGameEnroll/cancelEnroll", params);
  }
};
export default wyslApi;
