import service from "utils/service";

const llgApi = {
  getShopList: (params) => {
    return service.get("/dataProvide/getllg", {params});
  },
  getShopType:  (params) => {
    return service.get("/dataProvide/getllgType", {params});
  },
};
export default llgApi;
