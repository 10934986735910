import axios from 'axios';
import homeApi from 'api/home';
import { Toast, Notify } from 'vant';

const state = {
  digitalMembers: [
    {
      name: '小山竹',
      age: 5,
      relation: '女儿'
    },{
      name: '小可乐',
      age: 3,
      relation: '儿子'
    }
  ],
  currentMember: {}
}

const actions = {
  // 获取菜单
  getCatalogData({commit, state}, params) {
    homeApi.getCatalogData({
      name: state.name,
      type: state.selectedCatalog,
      ...params
    }).then(res => {
      console.log('res', res);
      if(res.flag) {
        commit('setMenuList', res.data);
      }
    })
  },
}

const mutations = {
  setDigitalMembers(state, data) {
    state.digitalMembers = data;
  },
  setCurrentMembers(state, data) {
    state.currentMember = data;
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
}