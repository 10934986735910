import axios from 'axios';
import homeApi from 'api/home';
import { Toast, Notify } from 'vant';

const state = {
  currentVideo: {}
}

const actions = {
  // 获取菜单
  getCatalogData({commit, state}, params) {
    homeApi.getCatalogData({
      name: state.name,
      type: state.selectedCatalog,
      ...params
    }).then(res => {
      console.log('res', res);
      if(res.flag) {
        commit('setMenuList', res.data);
      }
    })
  },
}

const mutations = {
  setDigitalMembers(state, data) {
    state.digitalMembers = data;
  },
  setCurrentVideo(state, data) {
    state.currentVideo = data;
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
}