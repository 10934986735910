import service from 'utils/service';
const jfdhApi = {
  //添加商品 
  addShopping: (params) => {
    return service.post('/shop/addShopping', params);
  },
  //查询当前用户积分
  getCurrentUserScore: () => {
    return service.get('/voucher/getCurrentUserScore')
  },
  
  //查询商品 1
  getShopping: (params) => {
    return service.post('/shop/getShopping', params)
  },
  
  //积分兑换商品券 dhDetail 有问题
  exchangeShopping: (params) => {
    return service.post('voucher/exchangeShopping', params);
  },

  //查询积分兑换记录列表 dhjl 
  getShopVouchers: (params) => {
    return service.post('voucher/getShopVouchers', params);
  },
  //生成兑换码 dhDetail
  getCode: (params) => {
    return service.post('voucher/getCode', params);
  },
  //兑换码兑换 dhDetail
  exchangeVoucher: (params) => {
    return service.post('voucher/exchangeVoucher', params);
  },
  //查询积分明细 jfmx
  getScoreDetails: (params) => {
    return service.post('voucher/getScoreDetails', params);
  },

  // 2022-0307版接口 开发者-郁力
  getScoreGoodList: params => {
    return service.post('/commodity/pageList', params );
  }, // 获取积分兑换商品列表
  getGoodsTypeList: params => {
    return service.get('/commodity/getTypeList', { params });
  }, // 获取商品类型
  getGoodDetail: params => {
    return service.get('/commodity/getCommodity', { params });
  }, // 获取商品详情
  getScoreOrderList: params => {
    return service.post('/commodityOrder/pageList', params);
  }, // 积分兑换订单列表
  deleteOrder: params => {
    return service.get('/commodityOrder/delete', { params });
  }, // 删除订单
  createOrder: params => {
    return service.post('/commodityOrder/add', params);
  }, // 兑换商品，生成订单
  scoreSign: params => {
    return service.get('/userSign/dsSign', { params });
  }, // 积分签到
  getMaxSignDay: params => {
    return service.get('/userSign/getMaxSignDay', { params });
  }, // 查询连续签到天数
  getMySign: params => {
    return service.get('/userSign/dsListMonth', { params });
  }, // 查询我的签到信息
  getOrderDetail: params => {
    return service.get('/commodityOrder/getDetail', { params });
  }, // 获取积分兑换订单详情

  // 2022-0309版接口  开发者-林良妥
  getIntegral: params => {
    return service.post('/integralRunning/getIntegral', params);
  }, // 参加获取积分
  getIntegralDetail: params => {
    return service.post('/integralRunning/getIntegralDetail', params);
  }, // 获取积分明细
  getTodayIntergral: params => {
    return service.get('/integralRunning/getTodayIntegral', { params });
  }, // 获取今日积分
  getUsableIntegral: params => {
    return service.get('/integralRunning/getUsableIntegral', { params });
  }, // 可用积分
  getIntergralWay: params => {
    return service.post('/integralType/getIntegralActive', params);
  }, // 分页查询获取积分活动
  getIntegralType: params => {
    return service.get('/integralType/getIntegralType', { params });
  }, // 积分类型
  checkUser: params => {
    return service.get('/integralRunning/checkUserIsBlack', { params });
  }, // 判断用户是否黑名单
};
export default jfdhApi;
