import Vue from 'vue'
import VueRouter from 'vue-router'
import context from '../main'
import store from '../store/index'
import { getQueryString } from '@/utils/index';
import api from 'api';
import { Dialog, Toast } from 'vant'

Vue.use(VueRouter)

const routes = [{
        path: '/wxLogin',
        name: 'wxLogin',
        component: () =>
            import ('../views/wxLogin.vue'),
    },
    {
        path: '/',
        name: 'index',
        component: () =>
            import ('../views/indexCopy.vue'),
        meta: {
            need2Login: true,
        },
    },
    {
        path: '/ylbxdj',
        name: 'ylbxdj',
        component: () =>
            import ('../views/ylbxdj.vue'),
        meta: {
            need2Login: true,
        },
    },
    {
        path: '/userInfo',
        name: 'userInfo',
        component: () =>
            import ('../views/user-info.vue'),
        meta: {
            need2Check: true,
        },
    },
    {
        path: '/loginPhone',
        name: 'loginPhone',
        component: () =>
            import ('../views/login/login-phone.vue'),
    },
    {
        path: '/loginHouse',
        name: 'loginHouse',
        component: () =>
            import ('../views/login/login-house.vue'),
    },
    {
        path: '/loginList',
        name: 'loginList',
        component: () =>
            import ('../views/login/login-list.vue'),
    },
    {
        path: '/community',
        name: 'community',
        component: () =>
            import ('../views/community.vue'),
    },
    {
        path: '/workRule',
        name: 'workRule',
        component: () =>
            import ('../views/workRule.vue'),
    },
    {
        path: '/park',
        name: 'park',
        component: () =>
            import ('../views/llcw/park.vue'),
        meta: {
            // need2Check: true,
        },
    },
    // {
    //   path: "/myPark",
    //   name: "myPark",
    //   component: () => import("../views/myPark.vue")
    // },
    {
        path: '/health',
        name: 'health',
        component: () =>
            import ('../views/health/index.vue'),
        meta: {
            need2Check: true,
        },
    },
    {
        path: '/health/main',
        name: 'health/main',
        component: () =>
            import ('../views/health/main.vue'),
    },
    {
        path: '/health/exam/list',
        name: 'health/exam-list',
        component: () =>
            import ('../views/health/exam-list.vue'),
    },
    {
        path: '/health/exam',
        name: 'health/exam',
        component: () =>
            import ('../views/health/exam.vue'),
    },
    {
        path: '/health/follow/list',
        name: 'health/follow-list',
        component: () =>
            import ('../views/health/follow-list.vue'),
    },
    {
        path: '/health/follow',
        name: 'health/follow',
        component: () =>
            import ('../views/health/follow.vue'),
    },
    {
        path: '/hotNews',
        name: 'hotNews',
        component: () =>
            import ('../views/hotNews/hotNews.vue'),
        meta: {
            // need2Login: true,
            title: "家头条",
        }
    },
    {
        path: '/loveActivity',
        name: 'loveActivity',
        component: () =>
            import ('../views/ddya/activity/loveActivity.vue'),
    },
    {
        path: '/loveActivityDetail',
        name: 'loveActivityDetail',
        component: () =>
            import ('../views/ddya/activity/loveActivityDetail.vue'),
    },
    {
        path: '/addloveActivity',
        name: 'addloveActivity',
        component: () =>
            import ('../views/ddya/activity/addActivity.vue'),
    },
    {
        path: '/loveDonate',
        name: 'loveDonate',
        component: () =>
            import ('../views/ddya/loveDonate.vue'),
    },
    {
        path: '/hotNewsEdit',
        name: 'hotNewsEdit',
        component: () =>
            import ('../views/hotNews/hotNewsEdit.vue'),
    },
    {
        path: '/qhdd',
        name: 'qhdd',
        component: () =>
            import ('../views/qhdd/qhdd.vue'),
    },
    {
        path: '/party',
        name: 'party',
        component: () =>
            import ('../views/dangjian/party.vue'),
    },
    {
        path: '/partyModified',
        name: 'partyModified',
        component: () =>
            import ('../views/dangjian/partyModified.vue'),
    },
    // {
    //   path: "/commList",
    //   name: "commlist",
    //   component: () => import("../views/commList.vue")
    // },
    {
        path: '/garbage',
        name: 'garbage',
        component: () =>
            import ('../views/garbage/garbage.vue'),
    },
    {
        path: '/vanguard',
        name: 'vanguard',
        component: () =>
            import ('../views/dangjian/vanguard.vue'),
    },
    {
        path: '/partyAct',
        name: 'partyAct',
        component: () =>
            import ('../views/dangjian/partyAct.vue'),
    },
    // {
    //   path: "/personCard",
    //   name: "personCard",
    //   component: () => import("../views/personCard.vue")
    // },
    {
        path: '/qt',
        name: 'qt',
        component: () =>
            import ('../views/garbage/qt.vue'),
    },
    {
        path: '/yh',
        name: 'yh',
        component: () =>
            import ('../views/garbage/yh.vue'),
    },
    {
        path: '/yf',
        name: 'yf',
        component: () =>
            import ('../views/garbage/yf.vue'),
    },
    {
        path: '/khs',
        name: 'khs',
        component: () =>
            import ('../views/garbage/khs.vue'),
    },
    // {
    //   path: "/oldKeep",
    //   name: "oldKeep",
    //   component: () => import("../views/oldKeep.vue")
    // },
    // 邻里车位
    {
        path: '/carPark',
        name: 'carPark',
        component: () =>
            import ('../views/llcw/carPark.vue'),
        // meta: {
        //     need2Check: true,
        // },
    },
    {
        path: '/property',
        name: 'property',
        component: () =>
            import ('../views/property/property.vue'),
        meta: {
            need2Check: true,
        },
    },
    // {
    //   path: "/carOrder",
    //   name: "carOrder",
    //   component: () => import("../views/carOrder.vue")
    // },
    {
        path: '/road',
        name: 'road',
        component: () =>
            import ('../views/dangjian/road.vue'),
    },
    {
        path: '/roadInfo',
        name: 'roadInfo',
        component: () =>
            import ('../views/dangjian/roadInfo.vue'),
    },
    {
        path: '/ddnews',
        name: 'ddnews',
        component: () =>
            import ('../views/qhdd/ddnews.vue'),
    },
    {
        path: '/ddStory',
        name: 'ddStory',
        component: () =>
            import ('../views/qhdd/ddStory.vue'),
    },
    {
        path: '/ddStoryList',
        name: 'ddStoryList',
        component: () =>
            import ('../views/qhdd/ddStoryList.vue'),
    },
    {
        path: '/ddxd',
        name: 'ddxd',
        component: () =>
            import ('../views/qhdd/ddxd.vue'),
    },
    {
        path: '/activity',
        name: 'activity',
        component: () =>
            import ('../views/llyjr/activity.vue'),
    },
    {
        path: '/ddnews-detail',
        name: 'ddnews-detail',
        component: () =>
            import ('../views/qhdd/ddnews-detail.vue'),
    },
    {
        path: '/microRun',
        name: 'microRun',
        component: () =>
            import ('../views/microRun.vue'),
    },
    // {
    //   path: "/share",
    //   name: "share",
    //   component: () => import("../views/share.vue")
    // },
    {
        path: '/study',
        name: 'study',
        component: () =>
            import ('../views/study.vue'),
    },
    // {
    //   path: "/newsList",
    //   name: "newsList",
    //   component: () => import("../views/newsList.vue")
    // },
    // {
    //   path: "/enjoyArt",
    //   name: "enjoyArt",
    //   component: () => import("../views/enjoyArt.vue")
    // },
    // {
    //   path: "/followHeart",
    //   name: "followHeart",
    //   component: () => import("../views/followHeart.vue")
    // },
    {
        path: '/contract',
        name: 'contract',
        component: () =>
            import ('../views/dangjian/contract.vue'),
    },
    {
        path: '/ylfw',
        name: 'ylfw',
        component: () =>
            import ('../views/ylfw/ylfw.vue'),
        meta: {
            need2Check: true,
        },
    },
    {
        path: '/gxfw',
        name: 'gxfw',
        component: () =>
            import ('../views/gxfw/gxfw.vue'),
        meta: {
            need2Check: true,
        },
    },
    {
        path: '/xzbm',
        name: 'xzbm',
        component: () =>
            import ('../views/xzbm.vue'),
        meta: {
            need2Check: true,
        },
    },
    {
        path: '/gjx',
        name: 'gjx',
        component: () =>
            import ('../views/gjx/gjx.vue'),
        meta: {
            need2Check: false,
        },
    },
    {
        path: '/llyjr',
        name: 'llyjr',
        component: () =>
            import ('../views/llyjr/llyjr.vue'),
        meta: {
            need2Check: false,
        },
    },
    {
        path: '/yqpdd',
        name: 'yqpdd',
        component: () =>
            import ('../views/yqpdd/yqpdd.vue'),
        meta: {
            need2Check: false,
        },
    },
    {
        path: '/yqpddDetail',
        name: 'yqpddDetail',
        component: () =>
            import ('../views/yqpdd/yqpddDetail.vue'),
        meta: {
            need2Check: false,
        },
    },
    {
        path: '/yqpddPM',
        name: 'yqpddPM',
        component: () =>
            import ('../views/yqpdd/yqpdd-pm.vue'),
    },
    {
        path: '/yqpddPY',
        name: 'yqpddPY',
        component: () =>
            import ('../views/yqpdd/yqpdd-py.vue'),
    },
    {
        path: '/yqpddPD',
        name: 'yqpddPD',
        component: () =>
            import ('../views/yqpdd/yqpdd-pd.vue'),
    },
    {
        path: '/yqpddAdd',
        name: 'yqpddAdd',
        component: () =>
            import ('../views/yqpdd/yqpdd-add.vue'),
    },
    {
        path: '/yqpddFk',
        name: 'yqpddFk',
        component: () =>
            import ('../views/yqpdd/yqpdd-fkpj.vue'),
    },
    {
        path: '/yqpddComment',
        name: 'yqpddComment',
        component: () =>
            import ('../views/yqpdd/yqpdd-comments.vue'),
    },
    {
        path: '/sdbxt',
        name: 'sdbxt',
        component: () =>
            import ('../views/sdbxt/sdbxt.vue'),
        meta: {
            need2Check: false,
            title: '社区学堂'
        },
    },
    {
        path: '/sdbxt/zmzyz',
        name: 'zmzyz',
        component: () =>
            import ('../views/sdbxt/zmzyz.vue'),
    },
    {
        path: '/sdbxt/tgyy',
        name: 'tgyy',
        component: () =>
            import ('../views/sdbxt/tgyy.vue'),
    },
    {
        path: '/sdbxt/xtsk',
        name: 'xtsk',
        component: () =>
            import ('../views/sdbxt/xtsk.vue'),
        meta: {
            need2Check: true,
        },
    },
    {
        path: '/ddya',
        name: 'ddya',
        component: () =>
            import ('../views/ddya/ddya.vue'),
        meta: {
            need2Check: false,
        },
    },
    {
        path: '/keyPeople',
        name: 'keyPeople',
        component: () =>
            import ('../views/keyPeople/keyPeople.vue'),
        meta: {
            need2Check: true,
        },
    },
    {
        path: '/wytp',
        name: 'wytp',
        component: () =>
            import ('../views/property/wytp.vue'),
    },
    {
        path: '/jmzz',
        name: 'jmzz',
        component: () =>
            import ('../views/jmzz.vue'),
    },
    {
        path: '/decoration',
        name: 'decoration',
        component: () =>
            import ('../views/property/decoration.vue'),
    },
    {
        path: '/service',
        name: 'service',
        component: () =>
            import ('../views/property/service.vue'),
    },
    {
        path: '/complain',
        name: 'complain',
        component: () =>
            import ('../views/property/complain.vue'),
    },
    {
        path: '/release',
        name: 'release',
        component: () =>
            import ('../views/property/release.vue'),
    },
    {
        path: '/payment',
        name: 'payment',
        component: () =>
            import ('../views/property/payment.vue'),
    },
    {
        path: '/comments',
        name: 'comments',
        component: () =>
            import ('../views/property/comments.vue'),
    },
    {
        path: '/communityMap',
        name: 'communityMap',
        component: () =>
            import ('../views/communityMap.vue'),
    },
    {
        path: '/gjxxq',
        name: 'gjxxq',
        component: () =>
            import ('../views/gjx/gjxxq.vue'),
    },
    {
        path: '/fxgj',
        name: 'fxgj',
        component: () =>
            import ('../views/gjx/fxgj.vue'),
        meta: {
            need2Check: true,
        },
    },
    {
        path: '/jygj',
        name: 'jygj',
        component: () =>
            import ('../views/gjx/jygj.vue'),
    },
    {
        path: '/wydz',
        name: 'wydz',
        component: () =>
            import ('../views/gjx/wydz.vue'),
        meta: {
            need2Check: true,
        },
    },
    {
        path: '/shareTool',
        name: 'shareTool',
        component: () =>
            import ('../views/gjx/shareTool.vue'),
    },
    // {
    //   path: "/eClassRoom",
    //   name: "eClassRoom",
    //   component: () => import("../views/eClassRoom.vue")
    // },
    {
        path: '/hlwhl',
        name: 'hlwhl',
        component: () =>
            import ('../views/ylfw/hlwhl.vue'),
    },
    {
        path: '/mbccf',
        name: 'mbccf',
        component: () =>
            import ('../views/ylfw/mbccf.vue'),
    },
    {
        path: '/cwyy',
        name: 'cwyy',
        component: () =>
            import ('../views/llcw/cwyy.vue'),
    },
    {
        path: '/wdyy',
        name: 'wdyy',
        component: () =>
            import ('../views/llcw/wdyy.vue'),
    },
    {
        path: '/wdcw',
        name: 'wdcw',
        component: () =>
            import ('../views/llcw/wdcw.vue'),
    },
    {
        path: '/cwxq',
        name: 'cwxq',
        component: () =>
            import ('../views/llcw/cwxq.vue'),
    },
    {
        path: '/yjnc',
        name: 'yjnc',
        component: () =>
            import ('../views/llcw/yjnc.vue'),
    },
    {
        path: '/znt',
        name: 'znt',
        component: () =>
            import ('../views/llyjr/znt.vue'),
    },
    {
        path: '/askQuestion',
        name: 'askQuestion',
        component: () =>
            import ('../views/llyjr/askQuestion.vue'),
    },
    {
        path: '/nrbPublishService',
        name: 'nrbPublishService',
        component: () =>
            import ('../views/llyjr/nrbPublishService.vue'),
    },
    {
        path: '/xlyzPublishService',
        name: 'xlyzPublishService',
        component: () =>
            import ('../views/llyjr/xlyz/xlyzPublishService.vue'),
    },
    {
        path: '/nrbDetail',
        name: 'nrbDetail',
        component: () =>
            import ('../views/llyjr/nrbDetail.vue'),
    },
    {
        path: '/nrb',
        name: 'nrb',
        component: () =>
            import ('../views/llyjr/nrb.vue'),
    },
    {
        path: '/serviceDetail',
        name: 'serviceDetail',
        component: () =>
            import ('../views/llyjr/serviceDetail.vue'),
    },
    {
        path: '/questionDetail',
        name: 'questionDetail',
        component: () =>
            import ('../views/llyjr/questionDetail.vue'),
    },
    {
        path: '/xlyz',
        name: 'xlyz',
        component: () =>
            import ('../views/llyjr/xlyz/index.vue'),
        meta: {
            title: '邻里陪聊'
        }
    },
    {
        path: '/xlyzDetail',
        name: 'xlyzDetail',
        component: () =>
            import ('../views/llyjr/xlyz/xlyzDetail.vue'),
    },
    {
        path: '/wysl',
        name: 'wysl',
        component: () =>
            import ('../views/llyjr/wysl.vue'),
    },
    {
        path: '/wyslDetail',
        name: 'wyslDetail',
        component: () =>
            import ('../views/llyjr/wyslDetail.vue'),
    },
    {
        path: '/addCw',
        name: 'addCw',
        component: () =>
            import ('../views/llcw/addCw.vue'),
    },
    {
        path: '/jfdhDetail',
        name: 'jfdhDetail',
        component: () =>
            import ('../views/ddya/jfdhDetail.vue'),
    },
    {
        path: '/dhjl',
        name: 'dhjl',
        component: () =>
            import ('../views/ddya/dhjl.vue'),
    },
    {
        path: '/dhmx',
        name: 'dhmx',
        component: () =>
            import ('../views/ddya/dhmx.vue'),
    },
    {
        path: '/jfmx',
        name: 'jfmx',
        component: () =>
            import ('../views/ddya/jfmx.vue'),
    },
    {
        path: '/dhDetailSure',
        name: 'dhDetailSure',
        component: () =>
            import ('../views/ddya/dhDetailSure.vue'),
    },
    {
        path: '/dhResult',
        name: 'dhResult',
        component: () =>
            import ('../views/ddya/dhResult.vue'),
    },
    {
        path: '/mrqd',
        name: 'mrqd',
        component: () =>
            import ('../views/ddya/mrqd.vue'),
    },
    {
        path: '/dhDetail',
        name: 'dhDetail',
        component: () =>
            import ('../views/ddya/dhDetail.vue'),
    },
    {
        path: '/jfRule',
        name: 'jfRule',
        component: () =>
            import ('../views/ddya/jfRule.vue'),
    },
    {
        path: '/jfdh',
        name: 'jfdh',
        component: () =>
            import ('../views/ddya/jfdh.vue'),
        // meta: {
        //   need2Check: true,
        // },
    },
    {
        path: '/jkxj',
        name: 'jkxj',
        component: () =>
            import ('../views/zxzx/jkxj.vue'),
    },
    {
        path: '/yqpddPJ',
        name: 'yqpddPJ',
        component: () =>
            import ('../views/yqpdd/yqpdd-pj.vue'),
    },
    {
        path: '/yqpddpjDetail',
        name: 'yqpddpjDetail',
        component: () =>
            import ('../views/yqpdd/yqpdd-pjDetail.vue'),
    },
    {
        path: '/cantakeDetail',
        name: 'cantakeDetail',
        component: () =>
            import ('../views/yqpdd/cantakeDetail.vue'),
    },
    {
        path: '/cantakeAdd',
        name: 'cantakeAdd',
        component: () =>
            import ('../views/yqpdd/cantakeAdd.vue'),
    },
    {
        path: '/yqpddpjMine',
        name: 'yqpddpjMine',
        component: () =>
            import ('../views/yqpdd/yqpdd-pjMine.vue'),
    },
    {
        path: '/yqpddpjAdd',
        name: 'yqpddpjAdd',
        component: () =>
            import ('../views/yqpdd/yqpdd-pjAdd.vue'),
    },
    {
        path: '/clothDonate',
        name: 'clothDonate',
        component: () =>
            import ('../views/ddya/clothDonate.vue'),
    },
    {
        path: '/xxbm',
        name: 'xxbm',
        component: () =>
            import ('../views/ddya/xxbm.vue'),
    },
    {
        path: '/sdbxt/tgPreview',
        name: 'tgPreview',
        component: () =>
            import ('../views/sdbxt/tgPreview.vue'),
        meta: {
            need2Check: true,
        },
    },
    // {
    //   path: "/ylkt",
    //   name: "ylkt",
    //   component: () => import("../views/ylkt.vue")
    // },
    {
        path: '/hdbm',
        name: 'hdbm',
        component: () =>
            import ('../views/ddya/hdbm.vue'),
    },
    {
        path: '/jfdhTicket',
        name: 'jfdhTicket',
        component: () =>
            import ('../views/ddya/jfdhTicket.vue'),
    },
    {
        path: '/jfdhList',
        name: 'jfdhList',
        component: () =>
            import ('../views/ddya/jfdhList.vue'),
    },
    {
        path: '/addressList',
        name: 'addressList',
        component: () =>
            import ('../views/ddya/addressList.vue'),
    },
    // {
    //     path: '/editAddress',
    //     name: 'editAddress',
    //     component: () =>
    //         import ('../views/ddya/editAddress.vue'),
    // },
    // {
    //   path: "/neiborVolunteer",
    //   name: "neiborVolunteer",
    //   component: () => import("../views/llyjr/neiborVolunteer.vue")
    // },
    // {
    //   path: "/yyx",
    //   name: "yyx",
    //   component: () => import("../views/yyx.vue")
    // },
    {
        path: '/wenEjia',
        name: 'wenEjia',
        component: () =>
            import ('../views/wenEJia/wenEjia.vue'),
    },
    {
        path: '/wenEjiaDetail',
        name: 'wenEjiaDetail',
        component: () =>
            import ('../views/wenEJia/wenEjiaDetail.vue'),
    },
    {
        path: '/sdbxtDetail',
        name: 'sdbxtDetail',
        component: () =>
            import ('../views/sdbxt/sdbxtDetail.vue'),
    },
    {
        path: '/wenEjiaLink',
        name: 'wenEjiaLink',
        component: () =>
            import ('../views/wenEJia/wenEjiaLink.vue'),
    },
    {
        path: '/indexCopy',
        name: 'indexCopy',
        component: () =>
            import ('../views/indexCopy.vue'),
    },
    {
        path: '/help',
        name: 'help',
        component: () =>
            import ('../views/llyjr/help.vue'),
    },
    {
        path: '/catalog',
        name: 'catalog',
        component: () =>
            import ('../views/catalog.vue'),
        meta: {
            need2Check: true,
        },
    },
    {
        path: '/sdbxt/volsign',
        name: 'volsign',
        component: () =>
            import ('../views/sdbxt/volsign.vue'),
    },
    {
        path: '/zntDbDetail',
        name: 'zntDbDetail',
        component: () =>
            import ('../views/llyjr/zntDbDetail.vue'),
    },
    {
        path: '/zntQuesDetail',
        name: 'zntQuesDetail',
        component: () =>
            import ('../views/llyjr/zntQuesDetail.vue'),
    },
    {
        path: '/joinZnt',
        name: 'joinZnt',
        component: () =>
            import ('../views/llyjr/joinZnt.vue'),
    },
    {
        path: '/myNrb',
        name: 'myNrb',
        component: () =>
            import ('../views/llyjr/myNrb.vue'),
    },
    {
        path: '/jointalk',
        name: 'jointalk',
        component: () =>
            import ('../views/llyjr/xlyz/joinTalk.vue'),
    },
    {
        path: '/coordination',
        name: 'coordination',
        component: () =>
            import ('../views/partyBuilding/coordination/coordination.vue'),
    },
    {
        path: '/building',
        name: 'building',
        component: () =>
            import ('../views/partyBuilding/building/building.vue'),
    },
    {
        path: '/hong',
        name: 'hong',
        component: () =>
            import ('../views/partyBuilding/hong/hong.vue'),
    },
    {
        path: '/dwgj',
        name: 'dwgj',
        component: () =>
            import ('../views/partyBuilding/hong/dwgj.vue'),
    },
    {
        path: '/butler',
        name: 'butler',
        component: () =>
            import ('../views/partyBuilding/butler/butler.vue'),
    },
    {
        path: '/eventAdd',
        name: 'eventAdd',
        component: () =>
            import ('../views/partyBuilding/butler/eventAdd.vue'),
    },
    {
        path: '/eventList',
        name: 'eventList',
        component: () =>
            import ('../views/partyBuilding/butler/eventList.vue'),
    },
    {
        path: '/eventListDetail',
        name: 'eventListDetail',
        component: () =>
            import ('../views/partyBuilding/butler/eventListDetail.vue'),
    },
    {
        path: '/ykIndex',
        name: 'ykIndex',
        component: () =>
            import ('../views/partyBuilding/ykyy/ykyy.vue'),
    },
    {
        path: '/Wfed',
        name: 'Wfed',
        component: () =>
            import ('../views/partyBuilding/ykyy/Wfed.vue'),
    },
    {
        path: '/wqcs',
        name: 'wqcs',
        component: () =>
            import ('../views/partyBuilding/ykyy/wqcs.vue'),
    },
    {
        path: '/zmkt',
        name: 'zmkt',
        component: () =>
            import ('../views/partyBuilding/ykyy/zmkt.vue'),
    },
    {
        path: '/wqtj',
        name: 'wqtj',
        component: () =>
            import ('../views/partyBuilding/ykyy/safeGuard.vue'),
    },
    {
        path: '/smfw',
        name: 'smfw',
        component: () =>
            import ('../views/partyBuilding/ykyy/smfw.vue'),
    },
    {
        path: '/kgh',
        name: 'kgh',
        component: () =>
            import ('../views/partyBuilding/kgh/kgh.vue'),
    },
    {
        path: '/zyya',
        name: 'zyya',
        component: () =>
            import ('../views/partyBuilding/kgh/zyya.vue'),
    },
    {
        path: '/lpjh',
        name: 'lpjh',
        component: () =>
            import ('../views/partyBuilding/kgh/lpjh.vue'),
    },
    {
        path: '/swys',
        name: 'swys',
        component: () =>
            import ('../views/partyBuilding/kgh/swys.vue'),
    },
    {
        path: '/hgxt',
        name: 'hgxt',
        component: () =>
            import ('../views/partyBuilding/kgh/hgxt.vue'),
    },
    {
        path: '/yspf',
        name: 'yspf',
        component: () =>
            import ('../views/partyBuilding/kgh/yspf.vue'),
    },
    {
        path: '/zbzx',
        name: 'zbzx',
        component: () =>
            import ('../views/partyBuilding/kgh/zbzx.vue'),
    },
    {
        path: '/kghDetail',
        name: 'kghDetail',
        component: () =>
            import ('../views/partyBuilding/kgh/kghDetail.vue'),
    },
    {
        path: '/wjwh',
        name: 'wjwh',
        component: () =>
            import ('../views/partyBuilding/kgh/wjwh.vue'),
    },
    {
        path: '/gqyz',
        name: 'gqyz',
        component: () =>
            import ('../views/partyBuilding/kgh/gqyz.vue'),
    },
    {
        path: '/kghList',
        name: 'kghList',
        component: () =>
            import ('../views/partyBuilding/kgh/list.vue'),
    },
    {
        path: '/partyBuilding',
        name: 'partyBuilding',
        component: () =>
            import ('../views/partyBuilding/partyBuilding.vue'),
    },
    {
        path: '/suggestion',
        name: 'suggestion',
        component: () =>
            import ('../views/partyBuilding/hong/suggestions.vue'),
    },
    {
        path: '/sourceStore',
        name: 'sourceStore',
        component: () =>
            import ('../views/partyBuilding/coordination/sourceStore.vue'),
    },
    {
        path: '/microEnergy',
        name: 'microEnergy',
        component: () =>
            import ('../views/partyBuilding/hong/microEnergy.vue'),
    },
    {
        path: '/memberManage',
        name: 'memberManage',
        component: () =>
            import ('../views/partyBuilding/coordination/memberManage.vue'),
    },
    {
        path: '/buildingMap',
        name: 'buildingMap',
        component: () =>
            import ('../views/partyBuilding/building/buildingMap.vue'),
    },
    {
        path: '/page',
        name: 'page',
        component: () =>
            import ('../views/partyBuilding/butler/page.vue'),
    },
    {
        path: '/introduce',
        name: 'introduce',
        component: () =>
            import ('../views/partyBuilding/butler/introduce.vue'),
    },
    {
        path: '/wiseMan',
        name: 'wiseMan',
        component: () =>
            import ('../views/partyBuilding/building/wiseMan.vue'),
    },
    {
        path: '/communityActivity',
        name: 'communityActivity',
        component: () =>
            import ('../views/llyjr/communityActivity.vue'),
    },
    {
        path: '/zntDetail',
        name: 'zntDetail',
        component: () =>
            import ('../views/llyjr/zntDetail.vue'),
    },
    {
        path: '/train',
        name: 'train',
        component: () =>
            import ('../views/partyBuilding/coordination/train.vue'),
    },
    {
        path: '/pulseList',
        name: 'pulseList',
        component: () =>
            import ('../views/partyBuilding/hong/pulseList.vue'),
    },
    {
        path: '/activityPool',
        name: 'activityPool',
        component: () =>
            import ('../views/partyBuilding/coordination/activityPool.vue'),
    },
    {
        path: '/knowledgeGroup',
        name: 'knowledgeGroup',
        component: () =>
            import ('../views/partyBuilding/coordination/knowledgeGroup.vue'),
    },
    {
        path: '/myRecommend',
        name: 'myRecommend',
        component: () =>
            import ('../views/partyBuilding/butler/myRecommend.vue'),
    },
    {
        path: '/videoDetail',
        name: 'videoDetail',
        component: () =>
            import ('../views/partyBuilding/coordination/videoDetail.vue'),
    },
    {
        path: '/articleDetail',
        name: 'articleDetail',
        component: () =>
            import ('../views/partyBuilding/coordination/articleDetail.vue'),
    },
    {
        path: '/goldenIdea',
        name: 'goldenIdea',
        component: () =>
            import ('../views/partyBuilding/butler/goldenIdea.vue'),
    },
    {
        path: '/activityNeeds',
        name: 'activityNeeds',
        component: () =>
            import ('../views/partyBuilding/coordination/activityNeeds.vue'),
    },
    {
        path: '/publishNeed',
        name: 'publishNeed',
        component: () =>
            import ('../views/partyBuilding/coordination/publishNeed.vue'),
    },
    {
        path: '/activityDetail',
        name: 'activityDetail',
        component: () =>
            import ('../views/partyBuilding/coordination/activityPoolDetail.vue'),
    },
    {
        path: '/activityNeedDetail',
        name: 'activityNeedDetail',
        component: () =>
            import ('../views/partyBuilding/coordination/activityNeedDetail.vue'),
    },
    {
        path: '/knowledgeGroupDetail',
        name: 'knowledgeGroupDetail',
        component: () =>
            import ('../views/partyBuilding/coordination/knowledgeGroupDetail.vue'),
    },
    {
        path: '/ddvanguard',
        name: 'ddvanguard',
        component: () =>
            import ('../views/partyBuilding/coordination/vanguard.vue'),
    },
    {
        path: '/myvanguard',
        name: 'myvanguard',
        component: () =>
            import ('../views/partyBuilding/coordination/myvanguard.vue'),
    },
    {
        path: '/vanguardAdd',
        name: 'vanguardAdd',
        component: () =>
            import ('../views/partyBuilding/coordination/vanguardAdd.vue'),
    },
    {
        path: '/duesPayment',
        name: 'duesPayment',
        component: () =>
            import ('../views/partyBuilding/coordination/duesPayment.vue'),
    },
    {
        path: '/publishWiseManNeed',
        name: 'publishWiseManNeed',
        component: () =>
            import ('../views/partyBuilding/building/publishWiseManNeed.vue'),
    },
    {
        path: '/publishRecommend',
        name: 'publishRecommend',
        component: () =>
            import ('../views/partyBuilding/butler/publishRecommend.vue'),
    },
    {
        path: '/publishGoldIdea',
        name: 'publishGoldIdea',
        component: () =>
            import ('../views/partyBuilding/butler/publishGoldIdea.vue'),
    },
    {
        path: '/educationSource',
        name: 'educationSource',
        component: () =>
            import ('../views/education/educationSource/educationSource.vue'),
    },
    {
        path: '/educationservice',
        name: 'educationservice',
        component: () =>
            import ('../views/education/service/service.vue'),
    },
    {
        path: '/huiStudy',
        name: 'huiStudy',
        component: () =>
            import ('../views/education/huiStudy/huiStudy.vue'),
    },
    {
        path: '/qlxfxt',
        name: 'qlxfxt',
        component: () =>
            import ('../views/education/huiStudy/qlxfxt.vue'),
    },
    {
        path: '/szjzkt',
        name: 'szjzkt',
        component: () =>
            import ('../views/education/huiStudy/szjzkt.vue'),
    },
    {
        path: '/szjzktDetail',
        name: 'szjzktDetail',
        component: () =>
            import ('../views/education/huiStudy/szjzktDetail.vue'),
    },
    {
        path: '/lncqkt',
        name: 'lncqkt',
        component: () =>
            import ('../views/education/huiStudy/lncqkt.vue'),
    },
    {
        path: '/lncqktDetail',
        name: 'lncqktDetail',
        component: () =>
            import ('../views/education/huiStudy/lncqktDetail.vue'),
    },
    {
        path: '/policyDepart',
        name: 'policyDepart',
        component: () =>
            import ('../views/education/educationSource/policyDepart.vue'),
    },
    {
        path: '/newsDetail',
        name: 'newsDetail',
        component: () =>
            import ('../views/education/educationSource/newsDetail.vue'),
    },
    {
        path: '/studySource',
        name: 'studySource',
        component: () =>
            import ('../views/education/educationSource/studySource.vue'),
    },
    {
        path: '/digitalMember',
        name: 'digitalMember',
        component: () =>
            import ('../views/education/educationSource/digitalMember.vue'),
    },
    {
        path: '/digitalMemberDetail',
        name: 'digitalMemberDetail',
        component: () =>
            import ('../views/education/educationSource/digitalMemberDetail.vue'),
    },
    {
        path: '/happyRoom',
        name: 'happyRoom',
        component: () =>
            import ('../views/education/huiStudy/happyRoom.vue'),
    },
    {
        path: '/happyRoomDetail',
        name: 'happyRoomDetail',
        component: () =>
            import ('../views/education/huiStudy/happyRoomDetail.vue'),
    },
    {
        path: '/parentRoom',
        name: 'parentRoom',
        component: () =>
            import ('../views/education/huiStudy/parentRoom.vue'),
    },
    {
        path: '/studyDetail',
        name: 'studyDetail',
        component: () =>
            import ('../views/education/huiStudy/studyDetail.vue'),
    },
    {
        path: '/studyRoom',
        name: 'studyRoom',
        component: () =>
            import ('../views/education/huiStudy/studyRoom.vue'),
    },
    {
        path: '/onlineStudy',
        name: 'onlineStudy',
        component: () =>
            import ('../views/education/huiStudy/onlineStudy.vue'),
    },
    {
        path: '/offlineStudy',
        name: 'offlineStudy',
        component: () =>
            import ('../views/education/huiStudy/offlineStudy.vue'),
    },
    {
        path: '/sharedSites',
        name: 'sharedSites',
        component: () =>
            import ('../views/partyBuilding/building/sharedSites.vue'),
    },
    {
        path: '/moreBuilding',
        name: 'moreBuilding',
        component: () =>
            import ('../views/partyBuilding/building/moreBuilding.vue'),
    },
    {
        path: '/hongxiaoer',
        name: 'hongxiaoer',
        component: () =>
            import ('../views/partyBuilding/coordination/hongxiaoer.vue'),
    },
    {
        path: '/hongxiaoerJoin',
        name: 'hongxiaoerJoin',
        component: () =>
            import ('../views/partyBuilding/coordination/hongxiaoerJoin.vue'),
    },
    {
        path: '/wxxd',
        name: 'wxxd',
        component: () =>
            import ('../views/partyBuilding/coordination/wxxd.vue'),
    },
    {
        path: '/edIndex',
        name: 'edIndex',
        component: () =>
            import ('../views/education/educationSource/educate/index.vue'),
    },
    {
        path: '/edApply',
        name: 'edApply',
        component: () =>
            import ('../views/education/educationSource/educate/apply.vue'),
    },
    {
        path: '/takClass',
        name: 'takClass',
        component: () =>
            import ('../views/education/educationSource/educate/takClass.vue'),
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: () =>
            import ('../views/education/educationSource/educate/feedback.vue'),
    },
    {
        path: '/mine',
        name: 'mine',
        component: () =>
            import ('../views/education/educationSource/educate/mine.vue'),
    },
    {
        path: '/map',
        name: 'map',
        component: () =>
            import ('../views/education/educationSource/educate/map.vue'),
    },
    {
        path: '/sharedSiteDetail',
        name: 'sharedSiteDetail',
        component: () =>
            import ('../views/partyBuilding/building/sharedSiteDetail.vue'),
    },
    {
        path: '/technology',
        name: 'technology',
        component: () =>
            import ('../views/education/huiStudy/technology.vue'),
    },
    {
        path: '/videoInfo',
        name: 'videoInfo',
        component: () =>
            import ('../views/education/huiStudy/videoInfo.vue'),
    },
    {
        path: '/articleInfo',
        name: 'articleInfo',
        component: () =>
            import ('../views/education/huiStudy/articleInfo.vue'),
    },
    {
        path: '/loveIndex',
        name: 'loveIndex',
        component: () =>
            import ('../views/catalog/loveindex.vue'),
    },
    {
        path: '/helpList',
        name: 'helpList',
        component: () =>
            import ('../views/catalog/helpList.vue'),
    },
    {
        path: '/llhz',
        name: 'llhz',
        component: () =>
            import ('../views/catalog/llhz.vue'),
    },
    {
        path: '/serveGroup',
        name: 'serveGroup',
        component: () =>
            import ('../views/catalog/serveGroup.vue'),
    },
    {
        path: '/myOrganization',
        name: 'myOrganization',
        component: () =>
            import ('../views/partyBuilding/hong/myOrganization.vue'),
    },
    {
        path: '/educationMap',
        name: 'educationMap',
        component: () =>
            import ('../views/education/educationMap/educationMap.vue'),
    },
    {
        path: '/educationDepartDetail',
        name: 'educationDepartDetail',
        component: () =>
            import ('../views/education/educationMap/educationDepart.vue'),
    },
    {
        path: '/borrowApply',
        name: 'borrowApply',
        component: () =>
            import ('../views/gjx/borrowApply.vue'),
    },
    {
        path: '/handleBorrow',
        name: 'handleBorrow',
        component: () =>
            import ('../views/gjx/handleBorrow.vue'),
    },
    {
        path: '/studySourceStore',
        name: 'studySourceStore',
        component: () =>
            import ('../views/education/educationSource/studySourceStore.vue'),
    },
    {
        path: '/studyVideoDetail',
        name: 'studyVideoDetail',
        component: () =>
            import ('../views/education/educationSource/studyVideoDetail.vue'),
    },
    {
        path: '/studyArticleDetail',
        name: 'studyArticleDetail',
        component: () =>
            import ('../views/education/educationSource/studyArticleDetail.vue'),
    },
    {
        path: '/evergreenClass',
        name: 'evergreenClass',
        component: () =>
            import ('../views/education/huiStudy/evergreenClass.vue'),
    },
    {
        path: '/myFamily',
        name: 'myFamily',
        component: () =>
            import ('../views/education/educationSource/myFamily.vue'),
    },
    {
        path: '/addFamily',
        name: 'addFamily',
        component: () =>
            import ('../views/education/educationSource/addFamily.vue'),
    },
    {
        path: '/editFamily',
        name: 'editFamily',
        component: () =>
            import ('../views/education/educationSource/editFamily.vue'),
    },
    {
        path: '/everGreenClassDetail',
        name: 'everGreenClassDetail',
        component: () =>
            import ('../views/education/huiStudy/everGreenClassDetail.vue'),
    },
    {
        path: '/qingtuanjiagou',
        name: 'qingtuanjiagou',
        component: () =>
            import ('../views/partyBuilding/hong/qingtuanjiagou.vue'),
    },
    {
        path: '/yikeyouyue',
        name: 'yikeyouyue',
        component: () =>
            import ('../views/partyBuilding/hong/yikeyouyue.vue'),
    },
    {
        path: '/statistic',
        name: 'statistic',
        component: () =>
            import ('../views/statistic.vue'),
    },
    {
        path: '/yijiagou',
        name: 'yijiagou',
        component: () =>
            import ('../views/partyBuilding/hong/yijiagou.vue'),
    },
    {
        path: '/yiweiquan',
        name: 'yiweiquan',
        component: () =>
            import ('../views/partyBuilding/hong/yiweiquan.vue'),
    },
    {
        path: '/needZj',
        name: 'needZj',
        component: () =>
            import ('../views/partyBuilding/hong/needZj.vue'),
    },
    {
        path: '/needLj',
        name: 'needLj',
        component: () =>
            import ('../views/partyBuilding/hong/needLj.vue'),
    },
    {
        path: '/needZx',
        name: 'needZx',
        component: () =>
            import ('../views/partyBuilding/hong/needZx.vue'),
    },
    {
        path: '/gxzj',
        name: 'gxzj',
        component: () =>
            import ('../views/partyBuilding/hong/gxzj.vue'),
    },
    {
        path: '/sharedPrint',
        name: 'sharedPrint',
        component: () =>
            import ('../views/sharedPrint.vue'),
        meta: {
            need2Check: true,
        },
    },
    {
        path: '/needHelp',
        name: 'needHelp',
        component: () =>
            import ('../views/partyBuilding/hong/needHelp.vue'),
    },
    {
        path: '/helpPreview',
        name: 'helpPreview',
        component: () =>
            import ('../views/partyBuilding/hong/helpPreview.vue'),
    },
    {
        path: '/yishuju',
        name: 'yishuju',
        component: () =>
            import ('../views/partyBuilding/hong/yishuju.vue'),
    },
    {
        path: '/fcyj',
        name: 'fcyj',
        component: () =>
            import ('../views/partyBuilding/hong/fcyj.vue'),
    },
    {
        path: '/fcyjMore',
        name: 'fcyjMore',
        component: () =>
            import ('../views/partyBuilding/hong/fcyjMore.vue'),
    },
    {
        path: '/yizuji',
        name: 'yizuji',
        component: () =>
            import ('../views/partyBuilding/hong/yizuji.vue'),
    },
    {
        path: '/huodongzhongxin',
        name: 'huodongzhongxin',
        component: () =>
            import ('../views/sdbxt/huodongzhongxin.vue'),
    },
    {
        path: '/huodongzhongxinDetail',
        name: 'huodongzhongxinDetail',
        component: () =>
            import ('../views/sdbxt/huodongzhongxinDetail.vue'),
    },
    {
        path: '/kegongzhendi',
        name: 'kegongzhendi',
        component: () =>
            import ('../views/partyBuilding/kgh/kegongzhendi.vue'),
    },
    {
        path: '/yspfDetail',
        name: 'yspfDetail',
        component: () =>
            import ('../views/partyBuilding/kgh/yspfDetail.vue'),
    },
    {
        path: '/alarm',
        name: 'alarm',
        component: () =>
            import ('../views/catalog/alarm.vue'),
    },
    {
        path: '/alarmList',
        name: 'alarmList',
        component: () =>
            import ('../views/catalog/alarmList.vue'),
    },
    {
        path: '/alarmOrder',
        name: 'alarmOrder',
        component: () =>
            import ('../views/catalog/alarmOrder.vue'),
    },
    {
        path: '/policyAdd',
        name: 'policyAdd',
        component: () =>
            import ('../views/catalog/policyAdd.vue'),
    },
    {
        path: '/findZuiMei',
        name: 'findZuiMei',
        component: () =>
            import ('../views/partyBuilding/hong/findzuimei.vue'),
    },
    {
        path: '/huanwuwei',
        name: 'huanwuwei',
        component: () =>
            import ('../views/partyBuilding/butler/huanwuwei.vue'),
    },
    {
        path: '/moreReSou',
        name: 'moreReSou',
        component: () =>
            import ('../views/partyBuilding/hong/moreReSou.vue'),
    },
    {
        path: '/moreInstructor',
        name: 'moreInstructor',
        component: () =>
            import ('../views/partyBuilding/hong/moreInstructor.vue'),
    },
    {
        path: '/bothLocationReport',
        name: 'bothLocationReport',
        component: () =>
            import ('../views/partyBuilding/building/bothLocationReport.vue'),
    },
    {
        path: '/locationReportDetail',
        name: 'locationReportDetail',
        component: () =>
            import ('../views/partyBuilding/building/locationReportDetail.vue'),
    },
    {
        path: '/areaPartyBuilding',
        name: 'areaPartyBuilding',
        component: () =>
            import ('../views/partyBuilding/hong/areaPartyBuilding.vue'),
    },
    {
        path: '/resouArticleDetail',
        name: 'resouArticleDetail',
        component: () =>
            import ('../views/partyBuilding/hong/articleDetail.vue'),
    },
    {
        path: '/recommendList',
        name: 'recommendList',
        component: () =>
            import ('../views/partyBuilding/butler/recommendList.vue'),
    },
    {
        path: '/myGoldenIdeaList',
        name: 'myGoldenIdeaList',
        component: () =>
            import ('../views/partyBuilding/butler/myGoldenIdeaList.vue'),
    },
    {
        path: '/coBuildingActivity',
        name: 'coBuildingActivity',
        component: () =>
            import ('../views/partyBuilding/building/coBuildingActivity.vue'),
    },
    {
        path: '/coBuildingActivityDetail',
        name: 'coBuildingActivityDetail',
        component: () =>
            import ('../views/partyBuilding/building/coBuildingActivityDetail.vue'),
    },
    {
        path: '/developPartyMember',
        name: 'developPartyMember',
        component: () =>
            import ('../views/partyBuilding/hong/developPartyMember.vue'),
    },
    {
        path: '/neighborMember',
        name: 'neighborMember',
        component: () =>
            import ('../views/partyBuilding/hong/neighborMember.vue'),
    },
    {
        path: '/yeweihui',
        name: 'yeweihui',
        component: () =>
            import ('../views/partyBuilding/butler/yeweihui.vue'),
    },
    {
        path: '/qingnianyishiting',
        name: 'qingnianyishiting',
        component: () =>
            import ('../views/partyBuilding/hong/qingnianyishiting.vue'),
    },
    {
        path: '/liubaochengnuo',
        name: 'liubaochengnuo',
        component: () =>
            import ('../views/partyBuilding/butler/liubaochengnuo.vue'),
    },
    {
        path: '/wtzzcl',
        name: 'wtzzcl',
        component: () =>
            import ('../views/partyBuilding/butler/wtzzcl.vue'),
    },
    {
        path: '/vanguardDetail',
        name: 'vanguardDetail',
        component: () =>
            import ('../views/partyBuilding/coordination/vanguardDetail.vue'),
    },
    {
        path: '/statisticChart',
        name: 'statisticChart',
        component: () =>
            import ('../views/statisticChart.vue'),
    },
    {
        path: '/noticeList',
        name: 'noticeList',
        component: () =>
            import ('../views/partyBuilding/hong/noticeList.vue'),
    },
    {
        path: '/myInvite',
        name: 'myInvite',
        component: () =>
            import ('../views/myInvite.vue'),
        meta: {
            need2Check: true,
        },
    },
    {
        path: '/luckTurnTable',
        name: 'luckTurnTable',
        component: () =>
            import ('../views/luckly/index.vue'),
        // meta: {
        //   need2Check: true,
        // },
    },
    {
        path: '/luckWdjp',
        name: 'luckWdjp',
        component: () =>
            import ('../views/luckly/wdjp.vue'),
    },
    {
        path: '/luckXqyg',
        name: 'luckXqyg',
        component: () =>
            import ('../views/luckly/xqyg.vue'),
    },
    {
        path: '/moreLuckyList',
        name: 'moreLuckyList',
        component: () =>
            import ('../views/luckly/moreLuckyList.vue'),
    },
    {
        path: '/editUserInfo',
        name: 'editUserInfo',
        component: () =>
            import ('../views/editUserInfo.vue'),
    },
    {
        path: '/scjgzz',
        name: 'scjgzz',
        component: () =>
            import ('../views/partyBuilding/butler/scjgzz.vue'),
    },
    {
        path: '/xyxxgs',
        name: 'xyxxgs',
        component: () =>
            import ('../views/partyBuilding/butler/xyxxgs.vue'),
    },
    {
        path: '/sqyyzz',
        name: 'sqyyzz',
        component: () =>
            import ('../views/partyBuilding/butler/sqyyzz.vue'),
    },
    {
        path: '/activityFk',
        name: 'activityFk',
        component: () =>
            import ('../views/partyBuilding/ActivityFk.vue'),
    },
    {
        path: '/hjlbn',
        name: 'hjlbn',
        component: () =>
            import ('../views/hjlbn/hjlbn.vue'),
        meta: {
            need2Check: false,
            title: '何姐来帮您'
        },
    },
    {
        path: '/hjlbnAdd',
        name: 'hjlbnAdd',
        component: () =>
            import ('../views/hjlbn/hjlbnAdd.vue'),
    },
    {
        path: '/hjlbnHelp',
        name: 'hjlbnHelp',
        component: () =>
            import ('../views/hjlbn/hjlbnHelp.vue'),
    },
    {
        path: '/wjys',
        name: 'wjys',
        component: () =>
            import ('../views/wjys.vue'),
    },
    {
        path: '/wjysAdd',
        name: 'wjysAdd',
        component: () =>
            import ('../views/wjysAdd.vue'),
    },
    {
        path: '/wjysDetail',
        name: 'wjysDetail',
        component: () =>
            import ('../views/wjysDetail.vue'),
    },
    {
        path: '/wjysHyjy',
        name: 'wjysHyjy',
        component: () =>
            import ('../views/wjysHyjy.vue'),
    },
    {
        path: '/jystjd',
        name: 'jystjd',
        component: () =>
            import ('../views/jystjd/jystjd.vue'),
        meta: {
            need2Check: false,
            title: '金钥匙调解'
        },
    },
    {
        path: '/jystjdNeedHelp',
        name: 'jystjdNeedHelp',
        component: () =>
            import ('../views/jystjd/jystjdNeedHelp.vue'),
    },
    {
        path: '/axst',
        name: 'axst',
        component: () =>
            import ('../views/axst/axst.vue'),
        meta: {
            need2Check: false,
            title: '爱心食堂'
        },
    },
    {
        path: '/axstDetail',
        name: 'axstDetail',
        component: () =>
            import ('../views/axst/axstDetail.vue'),
    },
    {
        path: '/payList',
        name: 'payList',
        component: () =>
            import ('../views/axst/payList.vue'),
    },
    {
        path: '/orderList',
        name: 'orderList',
        component: () =>
            import ('../views/axst/orderList.vue'),
    },
    {
        path: '/payOrderList',
        name: 'payOrderList',
        component: () =>
            import ('../views/axst/payOrderList.vue'),
    },
    {
        path: '/cancelOrder',
        name: 'cancelOrder',
        component: () =>
            import ('../views/axst/cancelOrder.vue'),
    },
    {
        path: '/sqxz',
        name: 'sqxz',
        component: () =>
            import ('../views/sqxz/index.vue'),
    },
    {
        path: '/sqxzMine',
        name: 'sqxzMine',
        component: () =>
            import ('../views/sqxz/mine.vue'),
    },
    {
        path: '/sqxzDetail',
        name: 'sqxzDetail',
        component: () =>
            import ('../views/sqxz/detail.vue'),
    },
    {
        path: '/sqxzPublic',
        name: 'sqxzPublic',
        component: () =>
            import ('../views/sqxz/public.vue'),
    },
    {
        path: '/zjy',
        name: 'zjy',
        component: () =>
            import ('../views/zjy/index.vue'),
    },
    {
        path: '/zjyDetail',
        name: 'zjyDetail',
        component: () =>
            import ('../views/zjy/detailPage.vue'),
    },
    {
        path: '/zxzx',
        name: 'zxzx',
        component: () =>
            import ('../views/zxzx/index.vue'),
    },
    {
        path: '/iwantAsk',
        name: 'iwantAsk',
        component: () =>
            import ('../views/zxzx/iwantAsk.vue'),
    },
    {
        path: '/djzx',
        name: 'djzx',
        component: () =>
            import ('../views/zxzx/djzx.vue'),
    },
    {
        path: '/partyActive',
        name: 'partyActive',
        component: () =>
            import ('../views/partyBuilding/hong/partyActive.vue'),
    },
    {
        path: '/zxzxDetail',
        name: 'zxzxDetail',
        component: () =>
            import ('../views/zxzx/zxzxDetail.vue'),
    },
    {
        path: '/healthHistory',
        name: 'HealthHistory',
        component: () =>
            import ('../views/health/history.vue'),
    },
    {
        path: '/guideIndex',
        name: 'guideIndex',
        component: () =>
            import ('../views/guideIndex.vue'),
    },
    {
        path: '/yundong',
        name: 'Yundong',
        component: () =>
            import ('../views/yundong/index.vue'),
    },
    {
        path: '/yundong/detail',
        name: 'YundongDetail',
        component: () =>
            import ('../views/yundong/detail.vue'),
    },
    {
        path: '/yundong/add',
        name: 'YundongAdd',
        component: () =>
            import ('../views/yundong/add.vue'),
    },
    {
        path: '/yundong/points',
        name: 'YundongPoints',
        component: () =>
            import ('../views/yundong/points.vue'),
    },
    {
        path: '/changguan',
        name: 'Changguan',
        component: () =>
            import ('../views/yundong/changguan/index.vue'),
    },
    {
        path: '/changguan/add',
        name: 'ChangguanAdd',
        component: () =>
            import ('../views/yundong/changguan/add.vue'),
    },
    {
        path: '/changguan/preview',
        name: 'ChangguanPreview',
        component: () =>
            import ('../views/yundong/changguan/preview.vue'),
    },
    {
        path: '/changguan/detail',
        name: 'ChangguanDetail',
        component: () =>
            import ('../views/yundong/changguan/detail.vue'),
    },
    {
        path: '/changguan/result',
        name: 'ChangguanResult',
        component: () =>
            import ('../views/yundong/changguan/result.vue'),
    },
    {
        path: '/repair',
        name: 'repair',
        component: () => import ('../views/repair/index.vue'),
        meta: {
            need2Check: true,
        }
    },
    {
        path: '/repairDetail',
        name: 'repairDetail',
        component: () =>
            import ('../views/repair/detail.vue'),
    },
    {
        path: '/llg', //邻里购
        name: 'llg',
        component: () =>
            import ('../views/llg/index.vue'),
    },
    {
        path: '/llg/goodsList', //邻里购
        name: 'llg_goodsList',
        component: () =>
            import ('../views/llg/goodsList.vue'),
    },
    {
        path: '/llg/goodsDetail', //邻里购
        name: 'llg_goodsDetail',
        component: () =>
            import ('../views/llg/goodsDetail.vue'),
    },
    {
        path: '/llg/order', //邻里购
        name: 'llg_order',
        component: () =>
            import ('../views/llg/order.vue'),
    },
    {
        path: '/llg/orderDetail', //邻里购
        name: 'llg_orderDetail',
        component: () =>
            import ('../views/llg/orderDetail.vue'),
    },
    {
        path: '/court',
        name: 'Court',
        component: () =>
            import ('../views/court/index.vue'),
    },
    {
        path: '/myCourt',
        name: 'myCourt',
        component: () =>
            import ('../views/court/myCourt.vue'),
    },
    {
        path: '/courtDetail',
        name: 'courtDetail',
        component: () => 
            import ('../views/court/courtDetail.vue'),
    },
    {
        path: '/court/add',
        name: 'CourtAdd',
        component: () =>
            import ('../views/court/add.vue'),
    },
    {
        path: '/refuse',
        name: 'Refuse',
        component: () =>
            import ('../views/refuse/index.vue'),
    },
    {
        path: '/refuse/detail',
        name: 'RefuseDetail',
        component: () =>
            import ('../views/refuse/detail.vue'),
    },
    {
        path: '/volunteerism',
        name: 'volunteerism',
        component: () =>
            import ('../views/volunteerism/index.vue'),
        meta: {
            need2Check: false,
            title: '时间银行'
        },
    },
    {
        path: '/volunteerRule',
        name: 'volunteerRule',
        component: () =>
            import ('../views/volunteerism/volunteerRule.vue'),
    },
    {
        path: '/volunteerTeam',
        name: 'volunteerTeam',
        component: () =>
            import ('../views/volunteerism/volunteerTeam.vue'),
    },
    {
        path: '/volunteerActivity',
        name: 'volunteerActivity',
        component: () =>
            import ('../views/volunteerism/volunteerActivity.vue'),
    },
    {
        path: '/volunteerApply',
        name: 'volunteerApply',
        component: () =>
            import ('../views/volunteerism/apply.vue'),
    },
    {
        path: '/uncorrupted',
        name: 'Uncorrupted',
        component: () =>
            import ('../views/uncorrupted/index.vue'),

    },
    {
        path: '/uncorrupted/list',
        name: 'Uncorrupted',
        component: () =>
            import ('../views/uncorrupted/list.vue'),

    }, {
        path: '/communityConvention',
        name: 'CommunityConvention', //社区公约
        component: () =>
            import ('../views/communityConvention/index.vue'),
    },
    {
        path: '/hdzx',
        name: 'hdzx', //社区公约
        component: () =>
            import ('../views/hdzx/index.vue'),
    },
    {
        path: '/wiseManDetail',
        name: 'wiseManDetail', //社区公约
        component: () =>
            import ('../views/partyBuilding/building/wiseManDetail.vue'),
    },
    {
        path: '/previewWiseMan',
        name: 'previewWiseMan', //社区公约
        component: () =>
            import ('../views/partyBuilding/building/previewWiseMan.vue'),
    },
    {
        path: '/jystjInfo',
        name: 'jystjInfo', //金钥匙调解详情
        component: () =>
            import ('../views/jystjd/jystjInfo.vue'),
    },
    {
        path: '/hjlbnDetail',
        name: 'hjlbnDetail', //何姐来帮你寻求帮助记录详情
        component: () =>
            import ('../views/hjlbn/hjlbnDetail.vue'),
    },
    {
        path: '/newPartyBuilding',
        name: 'newPartyBuilding', //何姐来帮你寻求帮助记录详情
        component: () =>
            import ('../views/partyBuilding/NewPartyBuilding.vue'),
    },{
        path: '/moreArticleList',
        name: 'moreArticleList', //文章更多页面
        component: () =>
            import ('../views/zxzx/moreArticleList.vue'),
    },
    {
        path: '/zljgYwh',
        name: 'zljgYwh', //业委会
        component: () =>
            import ('../views/zljg/ywh.vue'),
    },
    {
        path: '/governanceStructure',
        name: 'governanceStructure', //业委会
        component: () =>
            import ('../views/partyBuilding/GovernanceStructure.vue'),
    }, {
        path: '/zljgGs',
        name: 'zljgGs', //业委会
        component: () =>
            import ('../views/zljg/gs.vue'),
    },
    {
        path: '/zljgPeople',
        name: 'zljgPeople', //业委会
        component: () =>
            import ('../views/zljg/people.vue'),
    },
    {
        path: '/urbanRuralLinkage',
        name: 'urbanRuralLinkage', //业委会
        component: () =>
            import ('../views/urbanRuralLinkage/index.vue'),
    },
    {
        path: '/yqpddPmPy',
        name: 'yqpddPmPy', //业委会
        component: () =>
            import ('../views/yqpdd/yqpddPmPy.vue'),
    },
]

const router = new VueRouter({
    base: '/community/',
    mode: 'hash',
    routes,
})

router.beforeEach((to, from, next) => {
    //全局钩子函数
    to.matched.some((route) => {
        const { unionId } = router.history.pending.query
        const { ticket } = router.history.pending.query
        const code = getQueryString('code');
        // console.log('unionId', code)
        // 埋点
        const statisticAppData = sessionStorage.getItem('statisticAppData') ? JSON.parse(sessionStorage.getItem('statisticAppData')) : null;
        if (!statisticAppData) {
            api.getStatisticAppList({}).then(res => {
                if (res.flag && res.data) {
                    context.setSessionData('statisticAppData', res.data);
                }
            })
        } else if (Array.isArray(statisticAppData) && statisticAppData.find(item => item.dictValue === to.name)) {
            const obj = statisticAppData.find(item => item.dictValue === to.name);
            // console.log('obj', obj, statisticAppData);

            api.setVisit({ applyName: obj.dictLabel })
        }


        const token = context ? context.getSessionData('token') : ''
        if (unionId && !token) {
            // getToken
            store.dispatch('login/thirdLogin', {
                unionId,
                callback: () => {
                    next()
                },
            })
        } else if (ticket && !token) {
            // getToken
            store.dispatch('login/ticketLogin', {
                ticket,
                callback: () => {
                    next()
                },
            })
        } else if (code && !token) {
            // console.log('router');

            // getToken
            store.dispatch('login/getUserInfo', {
                code,
                callback: () => {
                    next();
                    // 系统埋点
                    api.setVisit({ applyName: '' });
                },
            })

        } else {
            if (route.meta.need2Check) {
                //通过此操作可以判断哪些页面需要实名验证
                store.dispatch('login/checkRealName', () => {
                    next()
                })
            } else {
                next()
            }
        }
    })
})

export default router