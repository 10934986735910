import service from "utils/service";
const huiStudyApi = {
  // 查询全部类别
  getHuiStudyTypes: params => {
    return service.get('/type/getTypes', { params });
  },
  // 分页查询课堂活动类列表（幸福学堂、家长课堂（亲子互动）、乐学课堂（线下讲座））
  getHuiActivityList: params => {
    return service.post('/classActivity/list', params);
  },
  // 课堂活动详情
  getHuiActivityDetail: params => {
    return service.get('/classActivity/detail', { params });
  },
  // 分页查询课堂活动反馈列表
  getHuiActivityFeedBackList: params => {
    return service.post('/classActivity/comments', params);
  },
  // 参加报名
  applyHuiActivity: params => {
    return service.post('/classActivity/addEnroll', params);
  },
  // 取消报名
  cancelHuiActivityEnroll: params => {
    return service.get('/classActivity/deleteEnroll', { params });
  }, 
  // 添加反馈
  huiActivityFeeadBack: params => {
    return service.post('/classActivity/addComment', params);
  },
  // 删除反馈
  cancelHuiActivityFeedBack: params => {
    return service.get('/classActivity/deleteComment', { params });
  },
  // 分页查询课堂文章类列表（家长课堂（家长学习）、乐学课堂（智能技术））
  getHuiArticleList: params => {
    return service.post('/classArticle/list', params);
  },
  // 课堂文章详情
  getHuiArticleDetail: params => {
    return service.get(`/classArticle/detail?id=${params.id}`);
  },
  // 浏览量埋点
  visitHuiArticle: params => {
    return service.get(`/classArticle/view?id=${params.id}`);
  },
  // 分页查询课堂文章评论
  getHuiArticleComments: params => {
    return service.post('/classArticle/comments', params);
  },
  // 添加文章评论
  commentHuiArticle: params => {
    return service.post('/classArticle/addComment', params);
  },
  // 分页查询课程视频列表
  getHuiVideoList: params => {
    return service.post('/classVideo/list', params);
  },
  // 视频课程详情
  getHuiVideoDetail: params => {
    return service.get(`/classVideo/detail?id=${params.id}`);
  },
  // 视频播放数埋点
  visitHuiVideo: params => {
    return service.get(`/classVideo/play${params.id}`);
  },
  // 分页查询视频课程评论
  getHuiVideoComments: params => {
    return service.post('/classVideo/comments', params);
  },
  // 添加视频评论
  commentHuiVideo: params => {
    return service.post('/classVideo/addComment', params);
  },
  // 老年常青课堂名师列表
  getTeacherList: params => {
    return service.get('/huiLearningElderly/getAll', {params});
  },
  // 获取名师详情
  getTeacherDetail: params => {
    return service.get('/huiLearningElderly/getById', {params});
  },
};
export default huiStudyApi;
