import axios from 'axios';

const state = {
  zntItemSelect: 'zntQues',
  zntDbSelected: '教育',
}

const actions = {
}

const mutations = {
  setZntItemSelect(state, data) {
    state.zntItemSelect = data;
  },
  setZntDbSelected(state, data) {
    state.zntDbSelected = data;
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
}