import service from "utils/service";
const luckyApi = {
  drawLotteryInformationListPage: params => {
    return service.post("/drawLotteryInformation/listPage", params);
  },
  getBanners: params => {
    return service.get("/drawLotteryInformation/getBanners", { params });
  },
  drawAwardSetListByLid: params => {
    return service.get("/drawAwardSet/listByLid", { params });
  },
  getDrawUserInformation: params => {
    return service.get("/drawWinningList/getDrawUserInformation", { params });
  },
  drawPrizeInformationListByLid: params => {
    return service.get("/drawPrizeInformation/listByLid", { params });
  },
  getPrize: params => {
    return service.post("/lottery/getPrize", params);
  },
  getPrizeByInfoId: params => {
    return service.get("/drawWinningPrize/getPrizeByInfoId", { params });
  },
  getPrizeByLid: params => {
    return service.get("/drawWinningPrize/getPrizeByLid", { params });
  },
  
};
export default luckyApi;
