import service from "utils/service";
const llyjrApi = {
  // 智囊团 、 能人帮、心理援助、 列表
  getPeopleSharePage: params => {
    return service.post("/neighborPeopleShare/commonSearch", params);
  },
  getClassType: params => {
    return service.get("/neighborPeopleShareClass/getClassType", { params });
  },
  getDetail: params => {
    return service.get("/neighborPeopleShare/getDetail", { params });
  },
  // 获取智囊团详情
  getZntDetail: params => {
    return service.get("/neighborWisdomGroup/detail", { params });
  },
  // 获取评论列表
  getCommentPage: params => {
    return service.post("/neighborPeopleShareComment/getCommentPage", params);
  },
  // 评论
  addComment: params => {
    return service.post("/neighborPeopleShareComment/addComment", params);
  },
  // 增加服务人数
  addServiceUp: params => {
    return service.get("/neighborPeopleShare/addServiceUp", { params });
  },
  addVisitUp: params => {
    return service.get("/neighborPeopleShare/addVisitUp", { params });
  },
  getPlhd: params => {
    return service.post('/neighborGame/getGamePage', params);
  },
  // 获取智囊团列表
  getGroupList: params => {
    return service.post("/neighborWisdomGroup/getGroupList", params);
  },
  // 加入智囊团
  addGroup: params => {
    return service.post("/neighborWisdomGroup/addGroup", params);
  },
  // 退出智囊团
  deleteGroup: params => {
    return service.post("/neighborWisdomGroup/deleteGroup", params);
  },
  // 我的提问和回答
  getMyCenterList: params => {
    return service.post("/neighborPeopleShareComment/center", params);
  },
  addThumbsUp: params => {
    return service.get("/neighborPeopleShareCommentThumbsUp/addThumbsUp", { params });
  },
  cancelThumbsUp: params => {
    return service.get("/neighborPeopleShareCommentThumbsUp/cancelThumbsUp", { params });
  },
};
export default llyjrApi;
