import Cookies from 'js-cookie';

/**
 *  获取本地存储的信息
 * @param {string} key 需要获取的信息的字段名
 * @return 返回所需要取得数据
 *
 */
function getLocalData(key) {
  let content = key === 'token' ? Cookies.get('token') : localStorage.getItem(key);
  // 字符串-json转换
  if (content) {
    try {
        return JSON.parse(content);
    } catch (e) {
        return content;
    }
  }
  return '';
}

/**
 *  设置本地存储信息
 * @param key 需要存储的信息的key
 * @param data 所需要存储的信息 字符串或json字符串
 */

function setLocalData(key, data) {
  if (typeof data === 'undefined') {
    data = '';
  }
  data = JSON.stringify(data);
  // 当key为设置token时，创建cookie，如果浏览器关闭，会自动注销账户
  key === 'token' ? Cookies.set(key, data) : localStorage.setItem(key, data);
}

/**
 *  移除本地存储信息
 * @param key
 */

function removeLocalData(key) {
  key === 'token' ? Cookies.remove(key) : localStorage.removeItem(key);
}

/**
 *  获取session存储的信息
 * @param {string} key 需要获取的信息的字段名
 * @return 返回所需要取得数据
 *
 */
function getSessionData(key) {
  let content = sessionStorage.getItem(key);
  // 字符串-json转换
  if (content) {
    try {
        return JSON.parse(content);
    } catch (e) {
        return content;
    }
  }
  return '';
}

/**
 *  设置session存储信息
 * @param key 需要存储的信息的key
 * @param data 所需要存储的信息 字符串或json字符串
 */

function setSessionData(key, data) {
  if (typeof data === 'undefined') {
    data = '';
  } else if (typeof data !== 'string') {
    data = JSON.stringify(data);
  }
  
  // 当key为设置token时，创建cookie，如果浏览器关闭，会自动注销账户
  sessionStorage.setItem(key, data);
}

/**
 *  移除session存储信息
 * @param key
 */

function removeSessionData(key) {
  sessionStorage.removeItem(key);
}

/**
 *  移除全部本地存储信息
 * @param
 */

function removeAllLocalData() {
  Cookies.remove('token');
  localStorage.clear();
  sessionStorage.clear();
}


function install(Vue) {
    Object.defineProperties(Vue.prototype, {
        getLocalData: {
            get() {
                return getLocalData;
            }
        },
        setLocalData: {
            get() {
                return setLocalData;
            }
        },
        removeLocalData: {
            get() {
                return removeLocalData;
            }
        },
        getSessionData: {
          get() {
              return getSessionData;
          }
        },
        setSessionData: {
            get() {
                return setSessionData;
            }
        },
        removeSessionData: {
            get() {
                return removeSessionData;
            }
        },
        removeAllLocalData: {
          get() {
            return removeAllLocalData;
          }
        }
    });
}

export default {
  install
};