import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
import VideoPlayer from 'vue-video-player';
import GeminiScrollbar from 'vue-gemini-scrollbar';
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css'
import vuescroll from "vuescroll";
import "vuescroll/dist/vuescroll.css";
import scroll from 'vue-seamless-scroll';
import axios from 'axios';
import VueAxios from "vue-axios";
import MintUI from 'mint-ui';
import ElementUI from 'element-ui';
import Vant from 'vant';
import Storage from 'utils/localData';
import 'vant/lib/index.css';
import 'mint-ui/lib/style.css';
import 'element-ui/lib/theme-chalk/index.css';
import api from './api/index';
import { Lazyload } from 'vant';


Vue.config.ignoredElements = ['wx-open-launch-app'];
Vue.use(Lazyload);
Vue.use(Storage);
Vue.use(VueAxios, axios);
//Vue.prototype.$axios = axios;
Vue.use(scroll);
Vue.use(vuescroll);
const vueLayer = layer(Vue);
import "vuescroll/dist/vuescroll.css";
Vue.use(dataV);
Vue.use(VideoPlayer);
Vue.use(GeminiScrollbar);
Vue.use(MintUI);
Vue.use(ElementUI);
Vue.use(Vant);

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

// 全局注册图标
Vue.component('icon', Icon);

// 适配flex
import '@/common/flexible.js';

// 引入全局css
import './assets/scss/style.scss';
import './assets/icons/index'

//引入高德地图
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
    key: '3b75d72b63b8762cf91567cd02bce434',
    plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType'],
    v: '1.4.4',
    uiVersion: '1.0.11'
});

//引入echart
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.$api = api; // 全局引入接口   可能还会用到qs，到时候调接口看看
window.layer = vueLayer

Vue.config.productionTip = false;

var vue = new Vue({
    router,
    store,
    render: (h) => h(App),
    // components: { App }
}).$mount('#app');

export default vue;