import service from 'utils/service';
const butlerApi = {
  // 合管家-获取首页环物委统计
  getHWWStaticData: params => {
    return service.get('/partyIncidentReport/statistic', { params });
  },
  // 合管家-分类查询各类别典型事件
  getClassicEventList: params => {
    return service.post('/partyIncidentReport/listPage', params);
  },
  getIncidentReportTypes: params => {
    return service.get('common/getIncidentReportTypes', { params });
  },
  // 合管家-上报事件
  reportEvent: params => {
    return service.post('/partyIncidentReport/report', params);
  },
  // 合管家-查看事件详情
  getEventDetail: params => {
    return service.post('/partyIncidentReport/detail', params);
  },
  // 合管家-获取首页推荐列表
  getRecommendList: params => {
    return service.post('/partyMyRecommend/index', params);
  },
  // 合管家-添加我的推荐
  recommend: params => {
    return service.post('/partyMyRecommend/addRecommend', params);
  },
  // 合管家-我的推荐列表
  getMyRecommendList: params => {
    return service.post('/partyMyRecommend/listPage', params);
  },
  // 合管家-删除推荐
  deleteRecommend: params => {
    return service.post('/partyMyRecommend/delRecommend', params);
  },
  // 合管家-首页金点子列表
  getProposalList: params => {
    return service.post('/partyMyProposal/index', params);
  },
  // 合管家-添加我的金点子
  addProposal: params => {
    return service.post('/partyMyProposal/addProposal', params);
  },
  // 合管家-我的金点子列表
  getMyProposalList: params => {
    return service.post('/partyMyProposal/listPage', params);
  },
  // 合管家-删除金点子
  deleteProposal: params => {
    return service.post('/partyMyProposal/delProposal', params);
  },
  // 获取基层站所
  getPartyBaseStation: params => {
    return service.post('/partyBaseStation/listPage', params);
  },
  // 预约社区小匠人
  previewWiseMan: params => {
    return service.post('/neighborServiceAppoint/addOne', params);
  }
}
export default butlerApi;