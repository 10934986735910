/*
 * @Author: mengdie.sun
 * @LastEditors: mengdie.sun
 * @Description: file content
 * @Date: 2021-06-18 13:49:01
 * @LastEditTime: 2021-07-05 14:06:30
 * @FilePath: \keqiao-dadu-community\src\api\yqpdd.js
 */
import service from 'utils/service';

const yqpddApi = {
    //查询热门拼单
    getHotOrders: (params) => {
        return service.post('/order/getHotOrders', params);
    },
    //查询拼单列表
    getOrders: (params) => {
        return service.post('/order/getOrders', params);
    },
    //查询拼单详情
    getOrderDetail: (params) => {
        return service.get(`/order/getOrderDetail/${params.orderId}`);
    },
    // 查看我的反馈
    getOrderEvaluate:(params) => {
        return service.get(`/order/getOrderEvaluate/${params.orderId}`);
    },
    //参加报名
    addOrderEnroll: (params) => {
        return service.post('/order/addOrderEnroll', params);
    },
    //取消报名
    deleteOrderEnroll: (params) => {
        return service.post(`/order/deleteOrderEnroll/${params.orderId}`);
    },

    //反馈评价
    addComment: (params) => {
        return service.post('/order/addComment', params);
    },
    //查询我发起的拼单
    getOwnLaunchOrders: (params) => {
        return service.post('/order/getOwnLaunchOrders', params);
    },
    //查询我拼成的
    getOwnCompleteOrders: (params) => {
        return service.post('/order/getOwnCompleteOrders', params);
    },
    //取消拼单活动
    deleteOrder: (params) => {
        return service.post('/order/deleteOrder', params);
    },
    //查询拼单类型
    getOrderType: (params) => {
        return service.get('/order/getOrderType');
    },
    //查询当前用户信息
    getCurrentUser: (params) => {
        return service.get('/order/getCurrentUser', params);
    },
    //上传图片
    uploadFile: (params) => {
        return service.post('/file/uploadFile', params);
    },
    //上传多个图片
    uploadMultiFile: (params) => {
        return service.post('/file/uploadMultiFile', params);
    },
    //发起拼单
    addOrder: (params) => {
        return service.post('/order/insertOrder', params);
    },
    //修改拼单
    updateOrderManage: (params) => {
        return service.post('/order/updateOrderManage', params);
    },
    // 获取拼单详情
    getOrderInfo: (params) => {
        return service.post('/order/getOrderInfo', params);
    },
    // 查询我要接孩子活动列表
    getChildrenList: (params) => {
        return service.post('/childrenToMeet/getChildrenToMeet', params);
    },
    // 查询活动详情
    getChildrenToMeetDetail: (params) => {
        return service.post('/childrenToMeet/getChildrenToMeetDetail', params);
    },
    // 我能接 查询任务列表
    getChildren: (params) => {
        return service.post('/childrenCanMeet/getChildren', params);
    },
    // 发布拼接
    publishRequire: (params) => {
        return service.post('/childrenToMeet/publishRequire', params);
    },
    // 发布服务
    publishService: (params) => {
        return service.post('/childrenCanMeet/publishService', params);
    },
    // 关闭接单
    closeOrder: (params) => {
        return service.post('/childrenToMeet/closeOrder', params);
    },
    // 开启接单
    openOrder: (params) => {
        return service.post('/childrenToMeet/openOrder', params);
    },
    // 接单已完成
    completeOrder: (params) => {
        return service.post('/childrenToMeet/completeOrder', params);
    },
    // 我要接单
    receiveOrder: (params) => {
        return service.post('/childrenToMeet/receiveOrder', params);
    },
    // 取消接单
    cancelOrder: (params) => {
        return service.post('/childrenToMeet/cancelOrder', params);
    },
    // 删除接单
    deleteRequire: (params) => {
        return service.post('/childrenToMeet/deleteRequire', params);
    },
    // 查询我的
    getOwnOrders: (params) => {
        return service.post('/childrenToMeet/getOwnOrders', params);
    },
    // 删除需求
    deleteService: (params) => {
        return service.post('/childrenCanMeet/delete', params);
    }
};
export default yqpddApi;