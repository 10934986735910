import service from "utils/service";
const zntApi = {
  pushThinkTank: params => {
    return service.post("/neighborPeopleShare/pushThinkTank", params);
  },
  getCommentPage: params => {
    return service.post("/neighborPeopleShareComment/getCommentPage", params);
  },
  addComment: params => {
    return service.post("/neighborPeopleShareComment/addComment", params);
  },
  addThumbsUp: params => {
    return service.get("/neighborPeopleShareCommentThumbsUp/addThumbsUp", {
      params
    });
  },
  cancelThumbsUp: params => {
    return service.get("/neighborPeopleShareCommentThumbsUp/cancelThumbsUp", {
      params
    });
  },
  deleteQuestion: params => {
    return service.post("/neighborPeopleShare/del", params);
  }
};
export default zntApi;
