import nrbApi from 'api/llyjr/nrb';
export default {
  state: {
    questionInfo: {},
    categoryList: [],
    classNameList: [],
    selectedTab: '我的发布',
    childrenToMeetDetail: {},
    canTakeDetail: {},
  },
  mutations: {
    setQuestionInfo(state, data) {
      state.questionInfo = data;
    },
    setCategoryList(state, data) {
      state.categoryList = data;
    },
    setClassNameList(state, data) {
      state.classNameList = data;
    },
    setSelectedTab(state, data) {
      state.selectedTab = data;
    },
    // 拼接-我要接 列表详情
    setChildrenToMeetDetail(state, data) {
      state.childrenToMeetDetail = data;
    },
    // 拼接-我能接 列表详情
    setCanTakeDetail(state, data) {
      state.canTakeDetail = data;
    }
  },
  actions: {
    getCategoryList({commit, state},_) {
      nrbApi.getCategoryList().then(res => {
        if(res.flag && res.data) {
          commit('setCategoryList', [{text: "全部类型", value: ""}, ...res.data.map(item => ({text: item, value: item}))])          
          commit('setClassNameList', res.data)
        }
      })
      .catch(err => {
        console.log('error====>', err);
        commit('setCategoryList', []);
      })
    }
  },
  modules: {}
};
