import service from "utils/service";
const hongApi = {
  // 获取优秀楼道指导员
  getInstructor: params => {
    return service.get('/partyInstructor/getInstructorExcellent', { params });
  },
  // 获取我的楼道指导员
  getMyInstructor: params => {
    return service.get('/partyInstructor/getInstructorList', { params });
  },
  // 我的党组织
  getMyCommunity: params => {
    return service.post('/partyOrganization/myOrganization', params);
  },
  // 党组织概况
  getCoummunityDetail: params => {
    return service.post('/partyOrganization/organizationSituation', params);
  },
  // 党员列表
  getCommunityMember: params => {
    return service.post('/partyOrganizationMember/partyMemberList', params);
  },
  // 伊柯有约-查询荣誉榜
  getHonorList: params => {
    return service.get('/party/getHonor', { params });
  },
  // 伊柯有约-查询妇联执委成员
  getFulianMembers: params => {
    return service.get('/contradiction/list', { params });
  },
  // 伊柯有约-我要咨询
  addConsult: params => {
    return service.post('/party/addConsult', params);
  },
  // 伊柯有约-分页查询我要求助列表
  woyaoqiuzhuList: params => {
    return service.post('/contradiction/list', params);
  },
  // 伊柯有约-求助预约
  addReserve: params => {
    return service.post('/contradiction/addReserve', params);
  },
  // 伊柯有约-分页查询专家 
  getExpertList: params => {
    return service.post('/expert/list', params);
  },
  // 伊柯有约-分页查询我要了解
  getLearnList: params => {
    return service.post('/learn/list', params);
  },
  // 伊柯有约-查询我要了解详情
  getLearnDetail: params => {
    return service.get('/learn/detail', { params });
  },
  // 伊柯有约-查询基础大数据
  getBaseBigData: params => {
    return service.get('/party/getBaseBigData', { params });
  },
  // 伊柯有约-查询维权大数据
  getWqBigData: params => {
    return service.get('/party/getWqBigData', { params });
  },
  // 伊柯有约-分页查询四比访
  getVisitList: params => {
    return service.post('/visit/list', params);
  },
  // 伊柯有约-比访详情
  getVisitDetail: params => {
    return service.get('/visit/detail', { params });
  },
  // 伊柯有约-增加比访浏览数
  viewVisit: params => {
    return service.get('/visit/view', { params });
  },
  // 伊柯有约-寻找最美
  getStepList: params => {
    return service.post('/beauty/get', params);
  },
  // 伊柯有约-分页查询文章列表（云上伊家、风采伊家）
  getArticleList: params => {
    return service.post('/party/articles', params);
  },
  // 伊柯有约-查询文章详情
  getArticleDetail: params => {
    return service.get('/party/articleDetail', { params });
  },
  
  // 获取热搜列表
  getHongArticleList: params => {
    return service.post('/party-article/getArticleList', params);
  },
  // 热搜浏览量
  addArticleVisit: params => {
    return service.post('/party-article/addArticleVisit', params);
  },
  // 获取文章详情
  getResouArticleDetail: params => {
    return service.get('/party-article/getArticle', { params });
  },
  // 获取东盛先锋列表
  getVanguardList: params => {
    return service.post('/partyCommunityPioneer/getCommunityPioneerList', params);
  },
  // 获取我的东盛先锋列表
  getMyVanguardList: params => {
    return service.post('/partyCommunityPioneer/getMyCommunityPioneer', params);
  },
  // 获取东盛先锋详情
  getVanguardDetail: params => {
    return service.post('/partyCommunityPioneer/getCommunityPioneer', params);
  },
  // 获取东盛先锋帖子评论
  getVanguardCommentList: params => {
    return service.post('/partyCommunityPioneerComment/getCommunityComment', params);
  },
  // 新增东盛先锋评论
  commentVanguard: params => {
    return service.post('/partyCommunityPioneerComment/addCommunityPioneer', params);
  },
  // 东盛先锋评论点赞
  zanVanguardComment: params => {
    return service.post('/partyCommunityPioneerComment/modCommunityCommentThumb', params);
  },
  // 东盛先锋新增
  addVanguard: params => {
    return service.post('/partyCommunityPioneer/addCommunityPioneer', params);
  },
  // 删除东盛先锋
  deleteVanguard: params => {
    return service.post('/partyCommunityPioneer/delDraftPioneer', params);
  },
  // 东盛先锋新增
  editVanguard: params => {
    return service.post('/partyCommunityPioneer/modDraftPioneer', params);
  },
  // 东盛先锋浏览量增加
  visitVanguard: params => {
    return service.post('/partyCommunityPioneer/addCommunityVisit', params);
  },
  // 东盛先锋点赞
  zanVanguard: params => {
    return service.post('/partyCommunityPioneer/modCommunityThumb', params);
  },
  // 我对组织有话说
  addSomethingSay: params => {
    return service.post('/partySomethingSay/addSomethingSay', params);
  },
  // 我归属的党支部及党小组
  MyAttribution: params => {
    return service.post('/partyOrganization/MyAttribution', params);
  },
  // 入党申请
  addPartyMember: params => {
    return service.post('/partyMember/addPartyMember', params);
  },
  // 申请加入红小二
  addHongXiaoEr: params => {
    return service.post('/partyHongVolunteer/add', params);
  },
  // 获取红小二数据
  getHongXiaoErData: params => {
    return service.post('partyHongVolunteer/list', params);
  },
  // 邻里党员
  addNeighborMember: params => {
    return service.post('/partyNeighborMember/add', params);
  },
  // 获取婚姻状况类型
  getMaritalState: params => {
    return service.get('/common/getMaritalState', { params });
  },
  // 获取先锋指数
  getMemberManageList: params => {
    return service.post('/partyPioneer/list', params);
  },
  // 获取妇联执委
  getFLZWData: params => {
    return service.get('/party/getMembers', { params });
  },
};
export default hongApi;
