import service from 'utils/service';

const loveActivityApi = {
    //发起活动
    getList: (params) => {
        return service.post('/volunteerActivity/list', params)
    },
    //活动详情
    getDetail: (params) => {
        return service.get(`/volunteerActivity/getActivityById/${params.vId}`)
    },
    getUserDetail: (params) => {
        return service.get(`/volunteerUser/getDetail/${params.vId}`)
    },
    //发起活动
    addActivity: (params) => {
        return service.post('/volunteerActivity/add', params)
    },
    //参加活动
    addUserActivity: (params) => {
        return service.post('/volunteerUser/add', params)
    },
    //爱心捐赠
    getBanner: () => {
        return service.post('/loveFoundTitle/getBanner')
    },
    //新衣捐赠
    donateClothes: (params) => {
        return service.post('/donateClothes/add', params)
    },
    //献血报名
    donateBlood: (params) => {
        return service.post('/donateBlood/add', params)
    },
    //获取献血机构
    getMechanism: () => {
        return service.get('/donateBlood/getMechanism')
    }
}

export default loveActivityApi;