import axios from 'axios';
import llgApi from 'api/llg';
import { Toast, Notify } from 'vant';

const state = {
  order: [], // 订单信息
}

const actions = {
  // 获取菜单
  
}

const mutations = {
  setOrder(state, data) {
    state.order = data;
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
}