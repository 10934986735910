import service from "utils/service";
const gjxApi = {
  getToolCasePage: params => {
    return service.post("/neighborToolCase/getToolCasePage", params);
  },
  rentTool: params => {
    return service.post("/neighborToolCase/rentTool", params);
  },
  cancelRentTool: params => {
    return service.get("/neighborToolCase/cancelRentTool", { params });
  },
  pushToolCase: params => {
    return service.post("/neighborToolCase/pushToolCase", params);
  },
  cancelThumbsUp: params => {
    return service.get("/neighborToolCaseThumbsUp/cancelThumbsUp", { params });
  },
  addThumbsUp: params => {
    return service.get("/neighborToolCaseThumbsUp/addThumbsUp", { params });
  },
  getBorrowCount: params => {
    return service.get('/neighborToolCaseRent/waitDeal', { params });
  },
  getBorrowList: params => {
    return service.post('/neighborToolCaseRent/listPage', params);
  },
  handleBorrow: params => {
    return service.post('/neighborToolCaseRent/checked', params);
  },
  getDetail: params => {
    return service.post('/neighborToolCase/getDetail', params);
  }
};
export default gjxApi;
