
const state = {
  buyList: [],
  hjlbnTab: '发布问题',
  jystjTab: '发布调解',
}

const actions = {}

const mutations = {
  setBuyList(state, data) {
    state.buyList = data;
  },
  setHjlbnTab(state, data) {
    state.hjlbnTab = data;
  },
  setJystjTab(state, data) {
    state.jystjTab = data;
  },
}

export default {
  namespaced: true,
  mutations,
  state,
}