import axios from 'axios';
import homeApi from 'api/home';
import { Toast, Notify } from 'vant';

const state = {
  selectedItem: '安全隐患',
  selectedStation: '综合执法',
  selectedItemPdd: '拼买',
}

const actions = {
  // 获取菜单
  
}

const mutations = {
  setSelectedItem(state, data) {
    state.selectedItem = data;
  },
  setSelectedItemPdd(state, data) {
    state.selectedItemPdd = data;
  },
  setSelectedStation(state, data) {
    state.selectedStation = data;
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
}