/*
 * @Author: your name
 * @Date: 2021-06-15 10:24:29
 * @LastEditTime: 2021-10-19 10:42:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /keqiao-dadu-community/src/api/common.js
 */
import service from "utils/service";
const commonApi = {
  // 获取所有小区
  getCommunityList: () => {
    return service.get("/common/getCommunityList");
  },
  // 上传多文件
  uploadMultiFile: params => {
    return service.post("/file/uploadMultiFile", params);
  },
  // 获取地址
  getAddress: () => {
    return service.get("/common/getAddress");
  },
  //查询当前用户信息
  getCurrentUser: (params) => {
    return service.get('/order/getCurrentUser', params);
  },
  // 查询统计信息
  getStatisticChartData: params => {
    return service.get('/dataStatisticsEvery/getDataByTime', {params});
  },
  // 统计埋点
  setVisit: params => {
    return service.post('/dataStatisticsInfo/insert', params);
  },
  // 获取需要埋点的应用列表
  getStatisticAppList: params => {
    return service.get('/common/getApplyTypes', { params });
  }
};
export default commonApi;
