import repairApi from "api/repair";

export default {
  namespaced: true,
  state: {
    repairList: [],
    repairDetail: {},
  },
  actions: {
    async getRepairList({ commit }, products) {
      try {
        const { data } = await repairApi.getRepairList(products);
        if (data) {
          const { records, total } = data;
          commit({
            type: "setState",
            repairList: records,
          });
          products.callback(total);
        }
      } catch (e) {
        //TODO handle the exception
        products.callback(null);
      }
    },
    async saveRepairInfo({ commit }, products) {
      try {
        const res = await repairApi.saveRepairInfo(products);
        const { status } = res;
        if (status === 200) {
          products.callback();
        }
      } catch (e) {
        //TODO handle the exception
        console.log(e);
      }
    },
    async getRepairById({ commit }, products) {
      try {
        const { data } = await repairApi.getRepairById(products);
        commit({
          type: "setState",
          repairDetail: data,
        });
      } catch (e) {
        //TODO handle the exception
      }
    },
    async uploadFile({ commit }, products) {
      try {
        const { data } = await repairApi.uploadFile(products);
        if (data) {
          products.callback(data);
        }
      } catch (e) {
        //TODO handle the exception
      }
    },
  },
  mutations: {
    setState(state, payload) {
      Object.keys(payload).forEach((el) => {
        state[el] = payload[el];
      });
    },
  },
};
