import service from "utils/service";

const repairApi = {
  getRepairList: (params) => {
    return service.post("/new/repairApply/list", params);
  },
  saveRepairInfo: (params) => {
    return service.post("/new/repairApply/add", params);
  },
  getRepairById: (params) => {
    return service.get("/new/repairApply/getDetail", { params });
  },
  uploadFile: (params) => {
    return service.post("/file/uploadMultiFile", params);
  },
};
export default repairApi;
