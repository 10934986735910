import nrbApi from 'api/llyjr/nrb';
export default {
  state: {
    dataInfo: {},
  },
  mutations: {
    setDataInfo(state, data) {
      state.dataInfo = data;
    },
  },
  actions: {
    // getCategoryList({commit, state},_) {
    //   nrbApi.getCategoryList().then(res => {
    //     if(res.flag && res.data) {
    //       commit('setCategoryList', [{text: "全部类型", value: ""}, ...res.data.map(item => ({text: item, value: item}))])          
    //       commit('setClassNameList', res.data)
    //     }
    //   })
    //   .catch(err => {
    //     console.log('error====>', err);
    //     commit('setCategoryList', []);
    //   })
    // }
  },
  modules: {}
};
