import axios from 'axios';
import { Toast, Dialog } from 'vant';
import service from 'utils/service';
import router from 'router/index';
import context from '../../main';

const state = {
  userInfo: {},
  isLogin: false,
  isRealName: false,
  community: {},
  buildingData: [],
  livingTypeData: [],
  houseList: [],
  phone: '',
}

const actions = {
  // 微信免登code获取用户信息
  getUserInfo({commit}, {code, callback}) {
    // console.log('code', code, callback);
    return axios.get(`/authApi/user/wechatLogin?code=${code}`).then(res => {
      if(res.data.flag && res.data.data) {
        // console.log('context', context, context.setSessionData);
        context.setSessionData('token', res.data.data.permit.token);
        context.setSessionData('userInfo', res.data.data);
        context.setSessionData('isLogin', true);
        context.setSessionData('isRealName', res.data.data.isRealName);
        context.setSessionData('checkStatus', res.data.data.checkStatus);
        commit('setUserInfo', res.data.data);
        commit('setIsLogin', true);
        commit('setIsRealName', res.data.data.isRealName);
        window.location.replace(window.location.href.replace(window.location.search, ''));
        if(callback) {
          callback();
        }
      } else {
        Toast.fail(res.data.message)
        commit('setIsLogin', false);
        context.removeAllLocalData();
        Toast('微信登录失败，请退出重试。')
        router.push('community')
      }
    }).catch(err => {
      console.log('error',err)
      commit('setIsLogin', false);
      context.removeAllLocalData();
      Toast('微信登录失败，请退出重试。')
      router.push('community')
    })
  },
  // 发送验证码
  sendMessage({commit}, {phone,callback}) {
    axios.get(`/authApi/user/sendCheckCode?phone=${phone}`).then(res => {
      if(res.data.flag && callback) {
        callback();
      } else {
        Toast.fail(res.data.message)
      }
    }).catch(err => {
      console.log('error',err)
    })
  },
  // 绑定手机号
  checkCode({commit}, {data,callback}) {
    const { phone, code, userId } = data;
    axios.get(`/authApi/user/checkCode?phone=${phone}&code=${code}&userId=${userId}`).then(res => {
      if(res.data.flag && callback) {
        callback();
        commit('setPhone', phone);
        commit('setIsRealName', true);
        context.setSessionData('phone', phone);
        context.setSessionData('isRealName', true);
      } else {
        Toast.fail(res.data.message);
        commit('setPhone', '');
        commit('setIsRealName', false);
        context.setSessionData('phone', '');
        context.setSessionData('isRealName', false);
      }
    })
  },
  // 获取小区信息
  getCommunityData({ commit }, {params, obj}) {
    service.get('/commonCondition/getCommunity', {params}).then(res => {
      if(res.flag) {
        obj.communityList = res.data;
      } else {
        Toast(res.message)
      }
    }).catch(err => {
      console.log('error===>', err);
    })
  },
  // 获取房屋信息
  getBuildingData({ commit, state }, obj) {
    service.get('/commonCondition/getHouseAddress', {
      params: {
        communityId: state.community.value || obj.communityId || ''
      }
    }).then(res => {
      if(res.flag) {
        commit('setBuildingData',res.data);
      } else {
        Toast(res.message)
      }
    }).catch(err => {
      console.log('error===>', err);
    })
  },
  // 获取住户类型信息
  getlivingTypeData({ commit, state }, obj) {
    service.get('/common/getRelationship', {}).then(res => {
      if(res.flag) {
        commit('setLivingType',res.data);
      } else {
        Toast(res.message)
      }
    }).catch(err => {
      console.log('error===>', err);
    })
  },
  // 是否进行房屋验证
  houseCheck({ commit, state }, obj) {
    service.get('/baseHousePerson/checkHouse').then(res => {
      if(res.flag) {
        commit('setHouseData',res.data);
      } else {
        Toast(res.message)
      }
    }).catch(err => {
      console.log('error===>', err);
    })
  },
  // 房屋验证
  handleHouseCheck({ commit, state }, {params, callback}) {

    const token = context.getSessionData("token") || "";
    axios({
      url: '/authApi/baseHousePerson/addBaseHousePerson',
      method: 'post',
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: token
        ? `Bearer ${token}`
        : "Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRoX3VzZXIiOnsidG9rZW4iOm51bGwsInVzZXJuYW1lIjoia3F6Z3l4IiwiYWRtaW4iOmZhbHNlLCJ1c2VySWQiOiIxIn19.kwZ-diYXAsjv6sPWr4vuYT4R4nfTHYzAy-297FUMiZWaWv4OA_i9mtVWqFkb7W0Ec3bBAQ9KXSmpX4K2Ph9_rg"
      },
      data: params,
    }).then(res => {
      if(res.data.flag && callback) {
        callback(res.data.data);
      } else {
        Toast(res.data.message)
      }
    }).catch(err => {
      console.log('error===>', err);
    })
  },
  // 验证是否实名验证
  checkRealName({commit, state}, callback) {
    const token = sessionStorage.getItem('token') || '';
    // console.log('token', token)
    axios({
      url: '/authApi/baseHousePerson/checkRealName',
      method: 'get',
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: token
        ? `Bearer ${token}`
        : ''
        // : "Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRoX3VzZXIiOnsidG9rZW4iOm51bGwsInVzZXJuYW1lIjoia3F6Z3l4IiwiYWRtaW4iOmZhbHNlLCJ1c2VySWQiOiIxIn19.kwZ-diYXAsjv6sPWr4vuYT4R4nfTHYzAy-297FUMiZWaWv4OA_i9mtVWqFkb7W0Ec3bBAQ9KXSmpX4K2Ph9_rg"
      },
      data: {},
    }).then(res => {
      if(res.data.flag) {
        const { isRealName, checkStatus, isChecked } = res.data.data;
        context.setSessionData('isRealName', isRealName);
        context.setSessionData('checkStatus', checkStatus);
        console.log(checkStatus, res.data);
        if(`${isChecked}` === '3' && callback) {
        // if(checkStatus === '已实名' && callback) {
          callback();
        } else if (`${isChecked}` === '2') {
        // } else if (checkStatus === '您的实名信息正在审核中!') {
          // Toast.fail(checkStatus);
          Dialog.confirm({
            title: '提示',
            message: '审核周期为2个工作日内，如有疑问请拨打（0575-87595331）',
          }).then(() => {
          }).catch((e) => {
          })
        } else {
          Dialog.confirm({
            title: '提示',
            message: '您暂未认证，认证后可体验东盛未来社区服务',
            confirmButtonText: '前往认证'
          }).then(() => {
            console.log('link')
            router.push('loginPhone');
          }).catch((e) => {
            // this.$router.go(-1);
            console.log('link',e)
          })
        }
      } else {
        // Toast('获取用户验证信息失败，请稍后重试');
        Dialog.confirm({
          title: '提示',
          message: '您暂未认证，认证后可体验东盛未来社区服务',
          confirmButtonText: '前往认证'
        }).then(() => {
          // console.log('link')
          router.push('loginPhone');
        }).catch((e) => {
          // this.$router.go(-1);
          // console.log('link',e)
        })
      }
    }).catch(err => {
      console.log('error===>', err);
    })
  },
  // 第三方手机登录
  thirdLogin({commit}, {unionId, callback}) {
    return axios.post('/authApi/sdk-auth/thirdLogin', { str: unionId }).then(res => {
      // console.log('thirdLogin', res.data);
      if(res.data.flag && res.data.data) {
        context.setSessionData('token', res.data.data.permit.token);
        context.setSessionData('userInfo', res.data.data);
        context.setSessionData('isLogin', true);
        context.setSessionData('isRealName', res.data.data.isRealName);
        context.setSessionData('checkStatus', res.data.data.checkStatus);
        commit('setUserInfo', res.data.data);
        commit('setIsLogin', true);
        commit('setIsRealName', res.data.data.isRealName);
        if (callback) {
          callback()
        }
      } else {
        Toast.fail(res.data.message)
        commit('setIsLogin', false);
        context.removeAllLocalData();
        Toast('登录失败')
        router.push('community')
      }
    }).catch(err => {
      console.log('error',err)
      commit('setIsLogin', false);
      context.removeAllLocalData();
      Toast('登录失败')
      router.push('community')
    })
  },
  // 浙政钉登录
  ticketLogin({commit}, {ticket, callback}) {
    return axios.post('/user/zlbLogin', { code: ticket }).then(res => {
      // console.log('thirdLogin', res.data);
      if(res.data.flag && res.data.data) {
        context.setSessionData('token', res.data.data.permit.token);
        context.setSessionData('userInfo', res.data.data);
        context.setSessionData('isLogin', true);
        context.setSessionData('isRealName', res.data.data.isRealName);
        context.setSessionData('checkStatus', res.data.data.checkStatus);
        commit('setUserInfo', res.data.data);
        commit('setIsLogin', true);
        commit('setIsRealName', res.data.data.isRealName);
        if (callback) {
          callback()
        }
      } else {
        Toast.fail(res.data.message)
        commit('setIsLogin', false);
        context.removeAllLocalData();
        Toast('登录失败')
        router.push('community')
      }
    }).catch(err => {
      console.log('error',err)
      commit('setIsLogin', false);
      context.removeAllLocalData();
      Toast('登录失败')
      router.push('community')
    })
  },
}

const mutations = {
  add(state, num) {
		state.itemNum += num;
  },
  // 给userInfo赋值
  setUserInfo(state, data) {
		state.userInfo = data;
  },
  setIsLogin(state, data) {
    state.isLogin = data;
  },
  setIsRealName(state, data) {
    state.isRealName = data;
  },
  setPhone(state, data) {
    state.phone = data;
  },
  selectCommunity(state, data) {
    state.community = data;
  },
  setBuildingData(state, data) {
    state.buildingData = data;
  },
  setLivingType(state, data) {
    state.livingTypeData = data;
  },
  setHouseData(state, data) {
    state.houseList = data;
  }
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
}