import service from "utils/service";
const nrbApi = {
  pushAbilityTeam: params => {
    return service.post("/neighborPeopleShare/pushAbilityTeam", params);
  },
  addThumbsUp: params => {
    return service.get("/neighborPeopleShareThumbsUp/addThumbsUp", {
      params
    });
  },
  cancelThumbsUp: params => {
    return service.get("/neighborPeopleShareThumbsUp/cancelThumbsUp", {
      params
    });
  },
  getNrbList: (params) => {
    return service.post('/neighborAbility/list', params);
  },
  getNrbDetail: (params) => {
    return service.get('/neighborAbility/detail', {params});
  },
  addNrbInfo: (params) => {
    return service.post('/neighborAbility/add', params);
  },
  updateNrbInfo: (params) => {
    return service.post('/neighborAbility/update', params)
  },
  addEnroll: (params) => {
    return service.post('/neighborAbilityEnroll/addEnroll', params)
  },
  cancelEnroll: (params) => {
    return service.post('/neighborAbilityEnroll/cancelEnroll', params);
  },
  getCategoryList: (params) => {
    return service.get('/neighborAbility/type', { params });
  }
};
export default nrbApi;
