import axios from "axios";
import { Toast } from "vant";
import context from '../main';

// 创建axios实例
const service = axios.create({
    timeout: 30000, // 请求超时时间
    baseURL: "/api" //请求前缀，根据实际情况修改
        // baseURL: 'https://ddsq.ikeqiao.net/api'
});

// request拦截器
service.interceptors.request.use(
    config => {
        Toast.loading({
            message: "加载中..."
        });
        const token = context.getSessionData('token');
        config.headers = {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: token ?
                `Bearer ${token}` :
                "Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRoX3VzZXIiOnsidG9rZW4iOm51bGwsInVzZXJuYW1lIjoia3F6Z3l4IiwiYWRtaW4iOmZhbHNlLCJ1c2VySWQiOiIxIiwibG9naW5JZCI6IjEifX0.CVGhx5jvv8O2wm7vhxaoTbhn5YZvZQIZCvARMy4D4sUyIWSby808-h0D-D-C7g_8-opWgZPJr74ybmbkTn22tw"
        };
        return config;
    },
    error => {
        // Do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// respone拦截器
service.interceptors.response.use(
    response => {
        Toast.clear();
        if (response.data.status !== 200 && !response.data.flag) {
            Toast(response.data.message);
        }
        return response.data;
    },
    error => {
        Toast.clear();
        console.log("err", error); // for debug
        Toast.fail(error.message, 2);
        return Promise.reject(error);
    }
);

export default service;