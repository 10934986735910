import axios from 'axios';
import llcwApi from 'api/llcw/llcw';
import { Toast, Notify } from 'vant';

const state = {
  park: {}, // 选择车位
}

const actions = {
  addCw(context, {item, obj}){
    item.startTime = item.startTime + ":00";
    item.overTime = item.overTime + ":00";
    llcwApi.addPark(item).then(res => {
        if (res.flag){
          obj.$messagebox("提示", "车位发布成功！");
          obj.$router.push('wdcw')
        }
    })
  },
  deleteImage(context, {path, obj}){
    llcwApi.deletePicture( {
      path: path
    }).then(res => {
      console.log(res);
      obj.sharePakringObj.image = null;
    })
  },
  uploadImage(context, {file, obj}){
    let data = new FormData();
    data.append("file", file.file)
    llcwApi.uploadParkingImage(data)
      .then(res=>{
        console.log('res=>',res);
        if(res.flag) {
          obj.sharePakringObj.image = res.data;
          obj.file[0].status = 'done';
          obj.file[0].message = '上传成功!';
        }else {
          obj.file[0].status = 'failed';
          obj.file[0].message = '上传失败!';
        }
    }).catch(err => {
      console.log(err);
      obj.file[0].status = 'failed';
      obj.file[0].message = '上传失败!';
    })  
  },
  getMyParking(context, {obj}){
    llcwApi.getMyParking().then(res => {
      console.log(res);
      if(res.flag) {
        obj.carPlates = res.data
      }
    })
  },
  getCommunityList(context, {obj}){
    llcwApi.getCommunityList().then(res => {
      if(res.flag) {
        obj.communities = obj.communities.concat(res.data)
      }
    })
  },
  // 获取我的共享车位列表
  getInitParkData(context, {flag, data, obj}) {
    console.log('getInitParkData', data);
    llcwApi.selectParking(data).then(res => {
      obj.listLoading = false;
      if(res.flag) {
        obj.pages = res.data.pages;
        if (flag){
          obj.parkingData = Array.from(new Set(obj.parkingData.concat(res.data.records)));
          if(res.data.current === res.data.pages) {
            Notify({
              message: '已经刷到底了！',
              color: '#ad0000',
              background: '#ffe1e1',
              duration: 1000
            });
            // obj.finished = true;
          }
        }else{
          obj.parkingData = res.data.records
        }
        obj.finished = true;
      } else {
        obj.parkingData = [];
      }
    })
    .catch(err => {
      console.log('error', err)
    });
  },


  // 获取我的共享车位列表
  getMyParkData(context, obj) {
    llcwApi.selectMyShareParking({}).then(res => {
      if(res.flag) {
        obj.list = res.data;
      }
    })
    .catch(err => {
      console.log('error', err)
    });
  },
  // 获取车位详情
  getParkInfo(context, {key, obj}) {
    llcwApi.getParkById({sharePakringId: key}).then(res => {
      if(res.flag) {
        obj.data = res.data;
      }
    })
    .catch(err => {
      console.log('error', err)
    });
  },
  // 获取车位状态
  getParkStatus(context, {key, obj}) {
    llcwApi.getParkingStatus({ sharePakringId: key}).then(res => {
      if(res.flag) {
        obj.state = res.data;
      }
    })
    .catch(err => {
      console.log('error', err)
    });
  },
  // 获取预约历史
  getRecords(context, {params, obj}) {
    llcwApi.getShareParkingOrderByPage(params).then(res => {
      if(res.flag) {
        if(params.page === 1) {
          obj.recordList = res.data.records || [];
        } else {
          obj.recordList.push(res.data.records || []);
        }
        obj.current = params.page;
      }
    }).catch(err => {
      console.log('error', err)
    });
  },
  // 停止共享
  stopShare({commit}, {params, callback, _this}) {
    // Toast('aaaaaa')
    llcwApi.stopShare(params).then(res => {
      if(res.flag) {
        _this.$message('操作成功！', '操作成功！')
        callback();
      } else {
        _this.$message('操作失败！', '操作失败！')
      }
    }).catch(err => {
      console.log('error', err)
    });
  },
  // 停止共享
  startShare({commit}, {params, callback, _this}) {
    // Toast('aaaaaa')
    llcwApi.startShare(params).then(res => {
      if(res.flag) {
        _this.$message('操作成功！', '操作成功！')
        callback();
      } else {
        _this.$message('操作失败！', '操作失败！')
      }
    }).catch(err => {
      console.log('error', err)
    });
  },
  // 获取我要停车数据
  getParkingData(context, {params, obj}) {
    llcwApi.selectParking(params).then(res => {
      if (params.object.startTime != ''){
        params.object.startTime = params.object.startTime + ":00";
      }
      if (params.object.startTime != ''){
        params.object.overTime = params.object.overTime + ":00";
      }
      if(res.flag) {
        if(params.page === 1) {
          obj.parkingData = res.data.records || [];
        } else {
          obj.parkingData.push(res.data.records || []);
        }
        obj.current = params.page;
      }
    }).catch(err => {
      console.log('error', err)
    });
  },
  // 获取我的预约单
  getMyOrders({commit, state}, {params, obj}) {
    llcwApi.getMyOrders(params).then(res => {
      if(res.flag) {
        obj.list = res.data.records;
      }
    })
  }
}

const mutations = {
  setPark(state, park) {
    state.park = park;
  }
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
}