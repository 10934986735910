import axios from 'axios';
import { Toast } from 'vant';
import store from '../store/index';
import context from '../main';

/**
 * @param {Function} fn 防抖函数
 * @param {Number} delay 延迟时间
 */
export function debounce(fn, delay) {
  var timer;
  return function () {
    var context = this;
    var args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time, fmt) {
  if (!time) return '';
  else {
    const date = new Date(time);
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      );
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        );
      }
    }
    return fmt;
  }
}

/**
 * @param {String} key 参数名
 */
export function getQueryString(key) {
  var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

/**
 * @description: 获取外链后跳转
 * @param {String} vote_url
 */
export function openUrl(vote_url) {
  store.dispatch('login/checkRealName', () => {
    const token = context.getSessionData('token') || 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRoX3VzZXIiOnsidG9rZW4iOm51bGwsInVzZXJuYW1lIjoia3F6Z3l4IiwiYWRtaW4iOmZhbHNlLCJ1c2VySWQiOiIxIn19.kwZ-diYXAsjv6sPWr4vuYT4R4nfTHYzAy-297FUMiZWaWv4OA_i9mtVWqFkb7W0Ec3bBAQ9KXSmpX4K2Ph9_rg';
    axios({
      method: 'get',
      url: `/authApi/sdk-auth/getUrl?sign=${vote_url}`,
      headers: { 'Authorization': token, timeout: 1000 * 10 }
    }).then(function(response) {
      if(response.data.flag) {
        // console.log(response.data.data.forecast)
        window.location.href = response.data.data;
        // console.log(response)
      } else {
        Toast.fail(response.data.message);
      }
    })
    .catch(function(err) {
      // eslint-disable-line no-unused-vars
      Toast.fail("提示", "页面加载失败");
      console.log('error====>', err);
      
    });
  });
}

/**
 * 检查是否登录
 */
export function getLoginStatus() {
  return this.$store.login.state.isLogin;
}

/**
 * 检查身份证号是否合法
 */
export function isIdentityId(identityId) {
  var patrn = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/;//长度或格式校验
  //地区校验
  var aCity = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外"
  };
  // 出生日期验证
  var sBirthday = (
    identityId.substr(6, 4) +
    "-" +
    Number(identityId.substr(10, 2)) +
    "-" +
    Number(identityId.substr(12, 2))
  ).replace(/-/g, "/"),
    d = new Date(sBirthday)
  // 身份证号码校验 最后4位  包括最后一位的数字/字母X
  var sum = 0,
    weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
    codes = "10X98765432";
  for (var i = 0; i < identityId.length - 1; i++) {
    sum += identityId[i] * weights[i];
  }
  var last = codes[sum % 11]; //计算出来的最后一位身份证号码
 
  var errorMsg = '';
  if (identityId === '') {
    errorMsg = "身份证号不能为空"
  } else if (!patrn.exec(identityId)) {
    errorMsg = "你输入的身份证长度或格式错误"
  } else if (!aCity[parseInt(identityId.substr(0, 2))]) {
    errorMsg = "你的身份证地区非法"
  } else if (sBirthday != d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate()) { errorMsg = "身份证上的出生日期非法" } else if (identityId[identityId.length - 1] != last) {
    errorMsg = "你输入的身份证号非法"
  }
  return errorMsg;
}

// 活动详情分享
export function sharePage(key, pageName, detail = {}, key2 = '') {
  const url = window.location.href.split('#')[0];
  axios.get(`/authApi/user/sign?url=${encodeURIComponent(url)}`).then(res => {
    const { wx } = window;
    if(res.data.flag) {
      const data = res.data.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appid, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature,// 必填，签名
        jsApiList: [
          'updateAppMessageShareData',
          "onMenuShareAppMessage", // 分享给朋友
          "onMenuShareTimeline", // 分享到朋友圈
          "onMenuShareQQ", // 分享到QQ
          "onMenuShareQZone", // 分享到QQ空间
          "checkJsApi", //判断当前客户端版本是否支持指定JS接口
          'wx-open-launch-weapp', // 微信开放标签
        ], // 必填，需要使用的JS接口列表
        openTagList:['wx-open-launch-weapp'] // 微信开放标签 小程序跳转按钮：<wx-open-launch-weapp>
      });
      wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        wx.checkJsApi({
          jsApiList: ['updateAppMessageShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function(res) {
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          // console.log(222222, res);
          }
        });
        wx.updateAppMessageShareData({
          title: '东盛未来社区', // 分享标题
          desc: detail.orderBaseInfo && detail.orderBaseInfo.title ? `${detail.orderBaseInfo.title}` : '更好的联接社区生活，打造更智能更便捷的社区体验', // 分享描述
          link: `http://dssq.ikeqiao.net/#/wxLogin?pageName=${pageName}&pageId=${key}&activeKey=${key2}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: detail.orderBaseInfo && detail.orderBaseInfo.imageIcon ? detail.orderBaseInfo.imageIcon : 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/main_select.png', // 分享图标
          success: function (e) {
            // console.log(3333331,'成功', e);
          },
          cancel: function (e) {
            // console.log(3333332,'取消', e);
          },
          complete: function (e) {
            // console.log(3333333,e);
          }
        });
        wx.onMenuShareTimeline({
          title: detail.orderBaseInfo && detail.orderBaseInfo.title ? detail.orderBaseInfo.title : '东盛未来社区', // 分享标题
          desc: detail.orderBaseInfo && detail.orderBaseInfo.title ? `${detail.orderBaseInfo.title}` : '更好的联接社区生活，打造更智能更便捷的社区体验', // 分享描述
          link: `http://dssq.ikeqiao.net/#/wxLogin?pageName=${pageName}&pageId=${key}&activeKey=${key2}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: detail.orderBaseInfo && detail.orderBaseInfo.imageIcon ? detail.orderBaseInfo.imageIcon : 'http://dssq.ikeqiao.net/data/image/dongsheng/indexPage/main_select.png', // 分享图标
          success: function (e) {
            // console.log(3333331,'成功', e);
          },
          cancel: function (e) {
            // console.log(3333332,'取消', e);
          },
          complete: function (e) {
            // console.log(3333333,e);
          }
        });
      });
      wx.error((e) => {
        console.log('error' ,e);
      })
    }
  }).catch(err => {
    console.log('error',err)
  })
}