import service from "utils/service";
const educationMapApi = {
  // 获取街道筛选条件
  getStreet: params => {
    return service.get("/learnStreet/getStreet", { params });
  },
  // 获取学习机构类型
  getPositionType: params => {
    return service.get("/learnPositionType/getPositionType", { params });
  },
  // 获取机构地址点位
  getPositionSimple: params => {
    return service.post("/learnPosition/getPositionSimple", params);
  },
  // 获取学习机构详情 1（点击地图坐标获取的详情）
  getPositionDetail: params => {
    return service.get("/learnPosition/getPositionDetail", { params });
  },
  // 获取学习机构详情 （机构详情页）
  getDepartDetail: params => {
    return service.get("/learnPosition/getPositionDetail2", { params });
  },
  // 分页查询学习机构活动
  getDepartActivity: params => {
    return service.get("/learnPositionActivity/getByPage", { params });
  },
  // 获取活动详情
  getActivityDetail: params => {
    return service.get("/learnPositionActivity/getDetail", { params });
  },
  // 评论分页查询
  getComments: params => {
    return service.get("/learnActivityComment/listByPage", { params });
  },
  // 评论
  comment: params => {
    return service.post("/learnActivityComment/save", params);
  },
  // 报名
  register: params => {
    return service.post("/learnActivitySign/activitySign", params);
  },
};
export default educationMapApi;
