
const state = {
  luckyActiveItem: {}
}

const actions = {}

const mutations = {
  setLuckyActiveItem(state, data) {
    state.luckyActiveItem = data;
  },
}

export default {
  namespaced: true,
  mutations,
  state,
}