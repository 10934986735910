export default {
  state: {
    detailInfo: {},
  },
  mutations: {
    setDetailInfo(state, data) {
      state.detailInfo = data;
    },
  },
  actions: {
    getCategoryList({commit, state},_) {

    }
  },
  modules: {}
};
