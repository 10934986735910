/* 请求接口 */
import gjxApi from './gjx';
import homeApi from './home';
import yqpddApi from './yqpdd';
import commonApi from './common';
import luckyApi from './lucky';
import jfdhApi from './ddya/jfdh';
import loveActivityApi from './ddya/loveActivity';
import llcwApi from './llcw/llcw';
import llyjrApi from './llyjr/index';
import nrbApi from './llyjr/nrb';
import wyslApi from './llyjr/wysl';
import xlyzApi from './llyjr/xlyz';
import zntApi from './llyjr/znt';
import sdbxtApi from './sdbxt/sdbxt';
import educationMapApi from './education/educationMap';
import hongApi from './partyBuilding/hong';
import butlerApi from './partyBuilding/butler';
import buildingApi from './partyBuilding/building';
import huiStudyApi from './education/huiStudy';

let api = Object.assign(
  {},
  luckyApi,
  gjxApi, 
  homeApi, 
  yqpddApi, 
  commonApi, 
  jfdhApi, 
  loveActivityApi, 
  llcwApi,
  llyjrApi,
  nrbApi,
  wyslApi,
  xlyzApi,
  zntApi,
  sdbxtApi,
  educationMapApi,
  hongApi,
  butlerApi,
  buildingApi,
  huiStudyApi,
);


export default api;
